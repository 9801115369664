import { Modal } from "react-bootstrap";

const AppModal = ({ title, children, ...props }) => {
    
    return (
        <Modal
            {...props}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center mb-5">
                { children }
            </Modal.Body>
        </Modal>
    );
}

export default AppModal;