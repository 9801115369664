import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import routes from "./routes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import BootstrapModal from "./components/BootstrapModal";
import { UserProfile, AssessorUpdateProfile, LandingPage, LoginPage, ForgotPassword, ResetPassword, AssessorForgotPassword, AssessorResetPassword, CompanyForgotPassword, CompanyResetPassword, DashboardPage, UserRequestPage, CompanyPage, CompanyUserPage, GamesPage, AssessorsPage, TalentStylePage, TalentStyleAttribute, ApplicationPage, ReportPage, RequestCode, RequestStatus, EnterAccess, UpdateProfile, AssessmentPage, CompanyReport, AssessorDashboard, AssessorProfile, AssessorReport, CreateUser, AssessmentBatches, AssessmentCandidates, CompanyApplications,  } from "./pages/";
 
const App = () => {
  return (
    <Router>
          <Routes>
            <Route exact={true} path={routes.LandingPage} element={<LandingPage />} />
            <Route exact={true} path={routes.AssessorLogin} element={<LoginPage />} />
            <Route exact={true} path={routes.SuperAdminLogin} element={<LoginPage />} />
            <Route exact={true} path={routes.CompanyAdminLogin} element={<LoginPage />} />
            <Route exact={true} path={routes.ForgotPassword} element={<ForgotPassword />} />
            <Route exact={true} path={routes.ResetPassword} element={<ResetPassword />} />
            <Route exact={true} path={routes.AssessorForgotPassword} element={<AssessorForgotPassword />} />
            <Route exact={true} path={routes.AssessorResetPassword} element={<AssessorResetPassword />} />
            <Route exact={true} path={routes.CompanyForgotPassword} element={<CompanyForgotPassword />} />
            <Route exact={true} path={routes.CompanyResetPassword} element={<CompanyResetPassword />} />
            <Route exact={true} path={routes.RequestCode} element={<RequestCode />} />
            <Route exact={true} path={routes.EnterAccess} element={<EnterAccess />} />
            <Route exact={true} path={routes.UpdateProfile} element={<UpdateProfile />} />
            <Route exact={true} path={routes.RequestStatus} element={<RequestStatus />} />
            <Route exact={true} path={routes.AssessorUpdateProfile} element={<AssessorUpdateProfile />} />
            <Route element={<AuthenticatedRoutes />}>
              <Route exact={true} path={routes.GamesPage} element={<GamesPage />} />
              <Route exact={true} path={routes.ReportPage} element={<ReportPage />} />
              <Route exact={true} path={routes.CompanyPage} element={<CompanyPage />} />
              <Route exact={true} path={routes.CompanyUserPage} element={<CompanyUserPage />} />
              <Route exact={true} path={routes.AssessorsPage} element={<AssessorsPage />} />
              <Route exact={true} path={routes.DashboardPage} element={<DashboardPage />} />
              <Route exact={true} path={routes.BootstrapModal} element={<BootstrapModal />} />
              <Route exact={true} path={routes.UserRequestPage} element={<UserRequestPage />} />
              <Route exact={true} path={routes.TalentStylePage} element={<TalentStylePage />} />
              <Route exact={true} path={routes.ApplicationPage} element={<ApplicationPage />} />
              <Route exact={true} path={routes.TalentStyleAttribute} element={<TalentStyleAttribute />} />

              <Route exact={true} path={routes.AssessmentPage} element={<AssessmentPage />} />
              <Route exact={true} path={routes.UserProfile} element={<UserProfile />} />
              <Route exact={true} path={routes.CompanyReport} element={<CompanyReport />} />
              <Route exact={true} path={routes.AssessorDashboard} element={<AssessorDashboard />} />
              <Route exact={true} path={routes.AssessorProfile} element={<AssessorProfile />} />
              <Route exact={true} path={routes.AssessorReport} element={<AssessorReport />} />
              <Route exact={true} path={routes.CreateUser} element={<CreateUser />} />
          <Route exact={true} path={routes.CompanyApplications} element={<CompanyApplications />} />
          <Route exact={true} path={routes.AssessmentBatches} element={<AssessmentBatches />} />
          <Route exact={true} path={routes.AssessmentCandidates} element={<AssessmentCandidates />} />
            </Route>
          </Routes>
    </Router>
  );
}

export default App;
