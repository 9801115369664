import React, { useEffect, useState } from "react";

import routes from "../../routes";
import store from "../../api/store";
import adminApis from "../../api/admin";
import useApi from "../../hooks/useApi";
import schemas from "../../utils/schemas";
import Modal from "../../components/Modal";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import styles from "../../styles/TalentStyle.module.css";
import { Form, SubmitButton, TextField } from "../../components/form";
import SelectField from "../../components/form/SelectField";
import { MDBSwitch } from "mdb-react-ui-kit";
import SwitchSelector from "react-switch-selector";

function Competency(props) {

    const [competency, setCompetency] = useState({})
    const addCompetencyApi = useApi(adminApis.addCompetency);

    const handleSubmit = async (e) => {
        e.preventDefault();
        addCompetencyApi.setLoading(true);

        const result = await addCompetencyApi.request(competency);
        if (result.ok) {
            helpers.toaster.success("Competency Created Successfully");
            window.location.reload(false);
        }
        //return navigate(routes.);
    }

    return (
        <Modal
            {...props}
            title="Add Competency"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form method="post" onSubmit={handleSubmit} className={styles.form}>
                <div className="row">
                    <div className="col-lg-12">
                        <label>Name</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setCompetency({ ...competency, name: e.target.value })}></input>
                    </div>
                    <div className="col-lg-12">
                        <label>Description</label>
                        <textarea required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, description: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-12">
                        <label>Add Insufficient Text here</label>
                        <textarea required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, insufficientText: e.target.value })}></textarea>
                        {/* <input type="text" id="" required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, insufficientText: e.target.value })}></input> */}
                    </div>
                    <div className="col-lg-12">
                        <label>Add Basic Text here</label>
                        <textarea required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, basicText: e.target.value })}></textarea>
                        {/* <input type="text" id="" required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, basicText: e.target.value })}></input> */}
                    </div>
                    <div className="col-lg-12">
                        <label>Add Good Text here</label>
                        <textarea required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, goodText: e.target.value })}></textarea>
                        {/* <input type="text" id="" required className="form-control" placeholder="" onChange={(e) => setCompetency({ ...competency, goodText: e.target.value })}></input> */}
                    </div>
                </div>
                <div className="text-center">
                    <button type="submit" className={styles.sendCode}>
                        {addCompetencyApi.loading ? "Submiting..." : "Submit"}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

function Skill(props) {

    const [skill, setSkill] = useState({})
    const addSkillApi = useApi(adminApis.addSkill);

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        addSkillApi.setLoading(true);

        //console.log(skill);

        const result = await addSkillApi.request(skill);
        if (result.ok) {
            helpers.toaster.success("Styles Created Successfully");
            window.location.reload(false);
        }
        //return navigate(routes.);
    }

    return (
        <Modal
            {...props}
            title="Add Styles"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form method="post" onSubmit={handleSubmit1} className={styles.form}>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Name</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setSkill({ ...skill, name: e.target.value })}></input>
                    </div>
                    <div className="col-lg-6">
                        <label>Description</label>
                        <textarea type="text" id="" required className="form-control" onChange={(e) => setSkill({ ...skill, description: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Quadrant1</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, title4: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Sub Text</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, subText4: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Primary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, primaryText4: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Secondary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, secondaryText4: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Quadrant2</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, title1: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Sub Text</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, subText1: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Primary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, primaryText1: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Secondary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, secondaryText1: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Quadrant3</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, title2: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Sub Text</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, subText2: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Primary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, primaryText2: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Secondary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, secondaryText2: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <label>Quadrant4</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, title3: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Sub Text</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, subText3: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Primary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, primaryText3: e.target.value })}></textarea>
                    </div>
                    <div className="col-lg-6">
                        <label>Secondary</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, secondaryText3: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <label>Text Y High</label>
                        <input type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, yaxisHigh: e.target.value })}></input>
                    </div>
                    <div className="col-lg-3">
                        <label>Text Y Low</label>
                        <input type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, yaxisLow: e.target.value })}></input>
                    </div>
                    <div className="col-lg-3">
                        <label>Text X High</label>
                        <input type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, xaxisHigh: e.target.value })}></input>
                    </div>
                    <div className="col-lg-3">
                        <label>Text X Low</label>
                        <input type="text" required className="form-control" placeholder="" onChange={(e) => setSkill({ ...skill, xaxisLow: e.target.value })}></input>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button type="submit" className={styles.sendCode}>
                        {addSkillApi.loading ? "Submiting..." : "Submit"}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

function SkillStatement(props) {

    const getSkillApi = useApi(adminApis.getSkillInfo);
    const [showSkill, setShowSkill] = useState([]);

    useEffect(() => {
        getSkillInfo();
    }, []);

    const getSkillInfo = async () => {
        const res = await getSkillApi.request();
        if (res.ok) setShowSkill(res.data.data);
    }

    const getSkillDataApi = useApi(adminApis.getSkillData);
    const [showSkillData, setShowSkillData] = useState([]);

    const handleChange = async (code) => {
        console.log(code);
        const res = await getSkillDataApi.request(code);
        if (res.ok) setShowSkillData(res.data.data);
        
    }

    const [skillStatement, setSkillStatement] = useState({})
    const addSkillStatementApi = useApi(adminApis.addSkillStatement);

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        addSkillStatementApi.setLoading(true);
        console.log(skillStatement);
        const result = await addSkillStatementApi.request({...skillStatement, code: getSkillDataApi.data.id});
        if (result.ok) {
            helpers.toaster.success("Styles Statement Created Successfully");
            window.location.reload(false);
        }
        //return navigate(routes.);
    }

    return (
        <Modal
            {...props}
            title="Add Styles Statement"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form method="post" onSubmit={handleSubmit1} className={styles.form}>
                <div className="row">
                    <div className="col-lg-12">
                        <label>Select Styles</label>
                        <select className="form-control" required onChange={(code) => handleChange(code.target.value)}>
                            <option>Choose Styles</option>
                            {showSkill.map((result) => (
                                <option value={result.code}>{result.name}</option>
                            ))
                            }
                        </select>
                    </div>
                    <div className="col-lg-12">
                        <label>Question</label>
                        <textarea type="text" required className="form-control" placeholder="" onChange={(e) => setSkillStatement({ ...skillStatement, question: e.target.value })}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <label>Statement 1</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setSkillStatement({ ...skillStatement, statement1: e.target.value })}></input>
                    </div>
                    <div className="col-lg-4">
                        <label>Quadrant</label>
                        <select className="form-control" required disabled={getSkillDataApi.loading} onChange={(e) => setSkillStatement({ ...skillStatement, quadrant1: e.target.value })}>
                            <option>Choose A Quadrant</option>
                            {getSkillDataApi?.data?.quadrants?.map((result, key) => (
                                <option key={key} value={result.id}>{result.title}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <label>Statement 2</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setSkillStatement({ ...skillStatement, statement2: e.target.value })}></input>
                    </div>
                    <div className="col-lg-4">
                        <label>Quadrant</label>
                        <select className="form-control" required disabled={getSkillDataApi.loading} onChange={(e) => setSkillStatement({ ...skillStatement, quadrant2: e.target.value })}>
                            <option>Choose A Quadrant</option>
                            {getSkillDataApi?.data?.quadrants?.map((result, key) => (
                                <option key={key} value={result.id}>{result.title}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <label>Statement 3</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setSkillStatement({ ...skillStatement, statement3: e.target.value })}></input>
                    </div>
                    <div className="col-lg-4">
                        <label>Quadrant</label>
                        <select className="form-control" required disabled={getSkillDataApi.loading} onChange={(e) => setSkillStatement({ ...skillStatement, quadrant3: e.target.value })}>
                            <option>Choose A Quadrant</option>
                            {getSkillDataApi?.data?.quadrants?.map((result, key) => (
                                <option key={key} value={result.id}>{result.title}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <label>Statement 4</label>
                        <input type="text" id="" required className="form-control" onChange={(e) => setSkillStatement({ ...skillStatement, statement4: e.target.value })}></input>
                    </div>
                    <div className="col-lg-4">
                        <label>Quadrant</label>
                        <select className="form-control" required disabled={getSkillDataApi.loading} onChange={(e) => setSkillStatement({ ...skillStatement, quadrant4: e.target.value })}>
                            <option>Choose A Quadrant</option>
                            {getSkillDataApi?.data?.quadrants?.map((result, key) => (
                                <option key={key} value={result.id}>{result.title}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button type="submit" className={styles.sendCode}>
                        {addSkillStatementApi.loading ? "Submiting..." : "Submit"}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

const QuestionaireTalent = (props) => {
    //const [options, setOptions] = useState([]);
    const [questionaire, setQuestionaire] = useState({});
    const addQuestionaireApi = useApi(adminApis.addQuestionaire);
    const [noOfOptions, setNoOfOptions] = useState(1);
    const [options, setOptions] = useState([]);

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        addQuestionaireApi.setLoading(true);

        const newOptions = [...new Set(options)];
        console.log(newOptions);

        const result = await addQuestionaireApi.request({...questionaire, options: newOptions});
        if (result.ok) {
            helpers.toaster.success("Competency Questionire Added Successfully");
            window.location.reload(false);
        }
    }


    const [competency, setCompetency] = useState([]);
    const getCompetencyApi = useApi(adminApis.getCompetencyInfo);

    useEffect(() => {
        getCompetencyInfo();
    }, []);

    const getCompetencyInfo = async () => {
        const res = await getCompetencyApi.request();
        if (res.ok) setCompetency(res.data.data);
    }

    return (
        <Modal
            {...props}
            title="Add Competency Questionaire"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form method="post" onSubmit={handleSubmit2} className={styles.form}>
                {/* <h4 align="left" className="mb-5">
                    Add Competency Questionaire
                </h4> */}
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <label>Choose Competency</label>
                        <select className="form-control" required onChange={(e) => setQuestionaire({ ...questionaire, competencyId: e.target.value })}>
                            <option selected disabled>Select a Competency</option>
                            {competency.map((result) => (
                                <option value={result.id}>{result.name}</option>
                            ))
                            }
                        </select>
                    </div>
                    <div className="col-lg-12">
                        <label>Question</label>
                        <input
                            type="text"
                            id=""
                            value={questionaire?.question}
                            className="form-control" required
                        onChange={(e) => setQuestionaire({ ...questionaire, question: e.target.value })}></input>
                    </div>
                </div>
               <div className="row">
                    {[...Array(noOfOptions)].map((_, key) => (
                        <div className="col-lg-12 mt-4" key={key}>
                            <label>Statement Options</label>
                            <input name="participants" required label="Participant Name" className="form-control" onBlur={(e) => {
                                e.target?.value && setOptions([...options, e.target.value]);
                            }
                            } />
                        </div>
                    )
                    )}
                    <div className="col-lg-12 mt-4">
                        <div className={styles.addMoreButton} onClick={() => setNoOfOptions(noOfOptions + 1)}>
                            <span>Add More Options</span>
                            <span className="btn btn-success"> +</span>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button type="submit" className={styles.sendCode}>
                        {addQuestionaireApi.loading ? "Submiting..." : "Submit"}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

const TalentStylePage = () => {

    const activeTab = JSON.parse(localStorage.getItem("activeTab"));
    const [options, setOptions] = useState([]);


    const [activePage, setActivePage] = useState(activeTab);
    const [modalVisible, setmodalVisible] = useState(false);
    const [modal1Visible, setmodal1Visible] = useState(false);
    const [modal2Visible, setmodal2Visible] = useState(false);
    const [modal3Visible, setmodal3Visible] = useState(false);
    const [editCompetency, setEditCompetency] = useState();
    const [editSkill, setEditSkill] = useState();
    const editCompetencyApi = useApi(adminApis.editCompetency);
    const editSkillApi = useApi(adminApis.editSkill);
    const [deleteQuestionaireID, setDeleteQuestionaireID] = useState();
    const editSkillStatementApi = useApi(adminApis.editSkillStatement);
    const [editSkillStatement, setEditSkillStatement] = useState();
    const [editSkillStatementModalVisible, setEditSkillStatementModalVisible] = useState(false);
    const editQuestionaireApi = useApi(adminApis.editQuestionaire);
    const [editQuestionaire, setEditQuestionaire] = useState();
    const [viewMore, setViewMore] = useState();

    const disableSkillStatusApi = useApi(adminApis.disableSkillStatus);
    const enableSkillStatusApi = useApi(adminApis.enableSkillStatus);

    const disableCompetencyStatusApi = useApi(adminApis.disableCompetencyStatus);
    const enableCompetencyStatusApi = useApi(adminApis.enableCompetencyStatus);

    const disableStatementStatusApi = useApi(adminApis.disableStatementStatus);
    const enableStatementStatusApi = useApi(adminApis.enableStatementStatus);

    const disableQuestionaireStatusApi = useApi(adminApis.disableQuestionaireStatus);
    const enableQuestionaireStatusApi = useApi(adminApis.enableQuestionaireStatus);

    const [noOfOptions, setNoOfOptions] = useState(0);


    const getCompetencyApi = useApi(adminApis.getCompetencyInfo);
    const [competency, setCompetency] = useState([]);

    useEffect(() => {
        getCompetencyInfo();
    }, []);

    const getCompetencyInfo = async () => {
        const res = await getCompetencyApi.request();
        if (res.ok) setCompetency(res.data.data);
    }

    const getSkillApi = useApi(adminApis.getSkillInfo);
    const [skill, setSkill] = useState([]);

    useEffect(() => {
        getSkillInfo();
    }, []);

    const getSkillInfo = async () => {
        const res = await getSkillApi.request();
        if (res.ok) setSkill(res.data.data);
    }

    const getSkillDisabledApi = useApi(adminApis.getSkillDisabled);
    const [skillDisabled, setSkillDisabled] = useState([]);

    useEffect(() => {
        getSkillDisabled();
    }, []);

    const getSkillDisabled = async () => {
        const res = await getSkillDisabledApi.request();
        if (res.ok) setSkillDisabled(res.data.data);
    }

    const getStatementDisabledApi = useApi(adminApis.getStatementDisabled);
    const [statementDisabled, setStatementDisabled] = useState([]);

    useEffect(() => {
        getStatementDisabled();
    }, []);

    const getStatementDisabled = async () => {
        const res = await getStatementDisabledApi.request();
        if (res.ok) setStatementDisabled(res.data.data);
    }

    const getCompetencyDisabledApi = useApi(adminApis.getCompetencyDisabled);
    const [competencyDisabled, setCompetencyDisabled] = useState([]);

    useEffect(() => {
        getCompetencyDisabled();
    }, []);

    const getCompetencyDisabled = async () => {
        const res = await getCompetencyDisabledApi.request();
        if (res.ok) setCompetencyDisabled(res.data.data);
    }

    const getQuestionaireDisabledApi = useApi(adminApis.getQuestionaireDisabled);
    const [questionaireDisabled, setQuestionaireDisabled] = useState([]);

    useEffect(() => {
        getQuestionaireDisabled();
    }, []);

    const getQuestionaireDisabled = async () => {
        const res = await getQuestionaireDisabledApi.request();
        if (res.ok) setQuestionaireDisabled(res.data.data);
    }

    const getQuadrantApi = useApi(adminApis.getQuadrant);
    const [quadrant, setQuadrant] = useState([]);

    useEffect(() => {
        getQuadrant();
    }, []);

    const getQuadrant = async () => {
        const res = await getQuadrantApi.request();
        if (res.ok) setQuadrant(res.data.data);
    }

    const getQuestionaireApi = useApi(adminApis.getQuestionaireInfo);
    const [questionaire, setQuestionaire] = useState([]);

    useEffect(() => {
        getQuestionaireInfo();
    }, []);

    const getQuestionaireInfo = async () => {
        const res = await getQuestionaireApi.request();
        if (res.ok) setQuestionaire(res.data.data);
    }

    const getSkillStatementApi = useApi(adminApis.getSkillStatement);
    const [skillStatement, setSkillStatement] = useState([]);

    useEffect(() => {
        getSkillStatement();
    }, []);

    const getSkillStatement = async () => {
        const res = await getSkillStatementApi.request();
        if (res.ok) setSkillStatement(res.data.data);
    }

    const [competencyToDelete, setCompetencyToDelete] = useState();
    const deleteCompetencyApi = useApi(adminApis.deleteCompetency);

    const handleDelete = async (code) => {
        setCompetencyToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Competency?");
        if (del) {
            const res = await deleteCompetencyApi.request(code);
            if (res.status === 422) helpers.toaster.error(res.data.responseMessage);
            if(res.ok) {
                helpers.toaster.success("Competency Successfully Deleted");
                window.location.reload(false);
            }
            
            // window.location.reload(false);
        }
    }

    const [skillToDelete, setSkillToDelete] = useState();
    const deleteSkillApi = useApi(adminApis.deleteSkill);

    const handleSkillDelete = async (code) => {
        setSkillToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Styles?");
        if (del) {
            const res = await deleteSkillApi.request(code);
            if (res.status === 422) helpers.toaster.error(res.data.responseMessage);
            if (res.ok) {
                helpers.toaster.success("Styles Successfully Deleted");
                window.location.reload(false);
            }

            // window.location.reload(false);
        }
    }

    const [skillStatementToDelete, setSkillStatementToDelete] = useState();
    const deleteSkillStatementApi = useApi(adminApis.deleteSkillStatement);

    const handleSkillStatementDelete = async (code) => {
        setSkillStatementToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Styles Statement?");
        if (del) {
            const res = await deleteSkillStatementApi.request(code);
            if (res.status === 422) helpers.toaster.error(res.data.responseMessage);
            if (res.ok) {
                helpers.toaster.success("Styles Successfully Deleted");
                window.location.reload(false);
            }

            // window.location.reload(false);
        }
    }


    const [questionaireToDelete, setQuestionaireToDelete] = useState();
    const deleteQuestionaireApi = useApi(adminApis.deleteQuestionaire);

    const deleteQuestionaireOptionApi = useApi(adminApis.deleteQuestionaireOption);

    const handleQuestionaireDelete = async (code) => {
        setQuestionaireToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Questionaire?");
        if (del) {
            const res = await deleteQuestionaireApi.request(code);
            if (res.status === 200) {
                helpers.toaster.success("Questionaire Successfully Deleted");
                //setQuestionaireToDelete(null);
                window.location.reload(false);
            }
            if(res.status === 422 || res.status === 500 || res.status === 400) { 
                helpers.toaster.error(res.data.responseMessage);
            }
            if (res.status === 500) {
                helpers.toaster.error("Data from this questionaire is referenced on another table.");
            }

            // window.location.reload(false);
        }
    }

    const handleQuestionaireOptionDelete = async (id, title) => {
        setDeleteQuestionaireID(id);
        const res = await deleteQuestionaireOptionApi.request(id);
        if(res.status === 422) helpers.toaster.error(res.data.responseMessage);
        if (res.ok) setOptions(options.filter(item => item.title !== title));
    }

    const handleEdit = async (data) => {

        const res = await editCompetencyApi.request({ ...data, code: editCompetency.code });

        if (res.ok) {
            helpers.toaster.success("Competency Updated Successfully");
            window.location.reload(false);
        }
        editCompetencyApi.setLoading(false);
        //return navigate(routes.);
    }

    const handleEdit1 = async (data) => {

        const res = await editSkillApi.request({ ...data, code: editSkill.code });

        if (res.ok) {
            helpers.toaster.success("Styles Updated Successfully");
            window.location.reload(false);
        }
        editSkillApi.setLoading(false);

        //return navigate(routes.);
    }

    const handleEdit2 = async (data) => {

        const res = await editSkillStatementApi.request(data);

        if (res.ok) {
            helpers.toaster.success("Styles Statement Updated Successfully");
            window.location.reload(false);
        }
        editSkillStatementApi.setLoading(false);

        //return navigate(routes.);
    }

    const handleEdit3 = async (data) => {
        const res = await editQuestionaireApi.request({ ...data, code: editQuestionaire.code });

        if (res.ok) helpers.toaster.success("Questionaire Updated Successfully");
        window.location.reload(false);

        editQuestionaireApi.setLoading(false);
    }

    const handleSkillDisable = async (code) => {
        const res = await disableSkillStatusApi.request(code);

        if(res.ok) {
            helpers.toaster.success("Styles Disabled Successfully");
            window.location.reload(false);
        }
    }

    const handleSkillEnable = async (code) => {
        const res = await enableSkillStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Styles Enabled Successfully");
            window.location.reload(false);
        }
    }

    const handleStatementDisable = async (code) => {
        const res = await disableStatementStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Statement Disabled Successfully");
            window.location.reload(false);
        }
    }

    const handleStatementEnable = async (code) => {
        const res = await enableStatementStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Statement Enabled Successfully");
            window.location.reload(false);
        }
    }

    const handleCompetencyDisable = async (code) => {
        const res = await disableCompetencyStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Competency Disabled Successfully");
            window.location.reload(false);
        }
    }

    const handleCompetencyEnable = async (code) => {
        const res = await enableCompetencyStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Competency Enabled Successfully");
            window.location.reload(false);
        }
    }

    const handleQuestionaireDisable = async (code) => {
        const res = await disableQuestionaireStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Questionaire Disabled Successfully");
            window.location.reload(false);
        }
    }

    const handleQuestionaireEnable = async (code) => {
        const res = await enableQuestionaireStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Questionaire Enabled Successfully");
            window.location.reload(false);
        }
    }


    const list = [
        {
            label: "Enabled",
            value: {
                enabled: true
            },
            selectedBackgroundColor: "#09b947",
        },
        {
            label: "Disabled",
            value: "disabled",
            selectedBackgroundColor: "#fbc531"
        }
    ];

    const onChange = (newValue) => {
        console.log(newValue);
    };

    const initialSelectedIndex = list.findIndex(({ value }) => value === "enabled");
    
    
    return (
        <Wrapper activePage={routes.TalentStylePage}>
            <div>
                <div className="row m-0 p-0">
                    <div className="col-lg-12 mb-5">
                        <h2 align="center">Styles & Competencies</h2>
                    </div>
                    <Competency
                        show={modal3Visible}
                        onHide={() => setmodal3Visible(false)}
                    />
                    <Skill
                        show={modal1Visible}
                        onHide={() => setmodal1Visible(false)}
                    />
                    <SkillStatement
                        show={modalVisible}
                        onHide={() => setmodalVisible(false)}
                    />
                    <QuestionaireTalent
                        show={modal2Visible}
                        onHide={() => setmodal2Visible(false)}
                    />
                </div>
                {activePage === "first" && <div className="row m-0 p-0">
                    <div className="col-lg-11 text-center m-auto px-5">                            
                        <button onClick={() => { setActivePage("first"); store.setItem("activeTab", JSON.stringify("first")) }} className={styles.tabActive}>Styles</button>
                        <button onClick={() => { setActivePage("second"); store.setItem("activeTab", JSON.stringify("second")) }} className={styles.tab}>Styles Statement</button>
                        <button onClick={() => {setActivePage("third"); store.setItem("activeTab", JSON.stringify("third"))}} className={styles.tab}>Competencies</button>
                        <button onClick={() => {setActivePage("forth"); store.setItem("activeTab", JSON.stringify("forth"))}} className={styles.tab}>Questionaires</button>
                    </div>
                    <div className="col-lg-11 m-auto">
                        <div className={styles.card1}>
                            <button
                                onClick={() => setmodal1Visible(true)}
                                className={styles.addskill}
                            >
                                +&nbsp;&nbsp;&nbsp; ADD STYLES
                            </button>
                            <table style={{marginTop: "20px"}}>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><span>Name</span></td>
                                    <td className={styles.left}><span>Description</span></td>
                                    <td className={styles.left}><span>Action</span></td>
                                </tr>
                                {skill.length === 0 && !getSkillApi.loading && <tr>No Skills available </tr>}
                                {getSkillApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    skill.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label></td>
                                        <td className={styles.left}><label>{item.description}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => setEditSkill(item)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleSkillDelete(item.code)} className={styles.delete}> <FaTrash /> {skillToDelete === item.code && deleteSkillApi.loading ? "Deleting.." : "Delete"} </button>&nbsp;&nbsp;<button type="submit" onClick={() => setViewMore(item)} className="btn btn-dark px-4" style={{ borderRadius: "20px", fontSize: "14px" }}> View More </button>
                                            <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleSkillDisable(item.code)} label={disableSkillStatusApi.loading ? "Disabling.." : "Enabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }

                                <tr className="mt-5"><td colSpan={3}><br></br><h5>Disabled Styles</h5></td><hr></hr></tr>

                                {skillDisabled.length === 0 && !getSkillDisabledApi.loading && <tr>No Disabled Styles available </tr>}
                                {getSkillDisabledApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    skillDisabled.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label></td>
                                        <td className={styles.left}><label>{item.description}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => setEditSkill(item)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleSkillDelete(item.code)} className={styles.delete}> <FaTrash /> {skillToDelete === item.code && deleteSkillApi.loading ? "Deleting.." : "Delete"} </button>&nbsp;&nbsp;<button type="submit" onClick={() => setViewMore(item)} className="btn btn-dark px-4" style={{ borderRadius: "20px", fontSize: "14px" }}> View More </button>
                                            <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch id="flexSwitchCheckChecked" onClick={() => handleSkillEnable(item.code)} label={enableSkillStatusApi.loading ? " Enabling.." : "Disabled"} style={{ fontSize: "20px" }} /></button>
                                            </td>
                                    </tr>)
                                }
                            </table>
                        </div>
                    </div>
                </div>}

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    title="Update Styles"
                    show={editSkill?.code}
                    onHide={() => setEditSkill(null)}
                >

                    <Form
                        enableReinitialize
                        validationSchema={schemas.editSkillSchema}
                        initialValues={{
                            name: editSkill?.name,
                            description: editSkill?.description,
                            id: editSkill?.id,
                            code: editSkill?.code,
                            subText1: editSkill?.quadrants[0].subText,
                            id1: editSkill?.quadrants[0].id,
                            primaryText1: editSkill?.quadrants[0].primaryText,
                            secondaryText1: editSkill?.quadrants[0].secondaryText,
                            title1: editSkill?.quadrants[0].title,
                            subText2: editSkill?.quadrants[1].subText,
                            id2: editSkill?.quadrants[1].id,
                            primaryText2: editSkill?.quadrants[1].primaryText,
                            secondaryText2: editSkill?.quadrants[1].secondaryText,
                            title2: editSkill?.quadrants[1].title,
                            id3: editSkill?.quadrants[2].id,
                            subText3: editSkill?.quadrants[2].subText,
                            primaryText3: editSkill?.quadrants[2].primaryText,
                            secondaryText3: editSkill?.quadrants[2].secondaryText,
                            title3: editSkill?.quadrants[2].title,
                            id4: editSkill?.quadrants[3].id,
                            subText4: editSkill?.quadrants[3].subText,
                            primaryText4: editSkill?.quadrants[3].primaryText,
                            secondaryText4: editSkill?.quadrants[3].secondaryText,
                            title4: editSkill?.quadrants[3].title,
                            yaxisHigh: editSkill?.yaxisHigh,
                            yaxisLow: editSkill?.yaxisLow,
                            xaxisHigh: editSkill?.xaxisHigh,
                            xaxisLow: editSkill?.xaxisLow,
                        }}
                        onSubmit={handleEdit1} className={styles.form}
                    >
                        <div className="row mx-4">
                            <div className="col-lg-12">
                                <TextField name={"name"} label={"Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-12">
                                <TextField textarea name={"description"} label={"Description"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"title1"} label={"Quadrant Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"subText1"} label={"Sub Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"primaryText1"} label={"Primary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"secondaryText1"} label={"Secondary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"title2"} label={"Quadrant Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"subText2"} label={"Sub Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"primaryText2"} label={"Primary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"secondaryText2"} label={"Secondary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"title3"} label={"Quadrant Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"subText3"} label={"Sub Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"primaryText3"} label={"Primary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"secondaryText3"} label={"Secondary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"title4"} label={"Quadrant Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"subText4"} label={"Sub Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"primaryText4"} label={"Primary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"secondaryText4"} label={"Secondary Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"yaxisHigh"} label={"Y Axis High"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"yaxisLow"} label={"Y Axis Low"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"xaxisHigh"} label={"X Axis High"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField name={"xaxisLow"} label={"X Axis Low"} className={styles.input} />
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <SubmitButton title={editSkillApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                        </div>
                    </Form>
                </Modal>  

                <Modal
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    title="Styles Details"
                    show={viewMore?.code}
                    onHide={() => setViewMore(null)}
                >
                    <div className="row px-5">
                        <div className="col-lg-3"><p align="left">Styles Name:</p></div>
                        <div className="col-lg-9"><h4 align="left"><label>{viewMore?.name}</label> </h4></div><hr></hr>
                        <div className="col-lg-3"><p align="left">Styles Description:</p></div>
                        <div className="col-lg-9"><h6 align="left"><label>{viewMore?.description}</label> </h6></div><hr></hr>
                        <div className="col-lg-3"><p align="left">Quadrants:</p></div><div className="col-lg-3"><p align="left">Sub Text:</p></div><div className="col-lg-3"><p align="left">Primary Text:</p></div><div className="col-lg-3"><p align="left">Secondary Text:</p></div><hr></hr>
                        <div className="col-lg-3">{viewMore?.quadrants.map((lists) => <p align="left"><label>{lists.title}</label></p>)}</div><div className="col-lg-3">{viewMore?.quadrants.map((lists) => <p align="left"><label>{lists.subText}</label></p>)}</div><div className="col-lg-3">{viewMore?.quadrants.map((lists) => <p align="left"><label>{lists.primaryText}</label></p>)}</div><div className="col-lg-3">{viewMore?.quadrants.map((lists) => <p align="left"><label>{lists.secondaryText}</label></p>)}</div><hr></hr>
                        <div className="col-lg-3"><p align="left">Y Axis Text:</p></div>
                        <div className="col-lg-9"><h6 align="left"><label>(High) {viewMore?.yaxisHigh} </label>&nbsp;&nbsp; <label>(Low)  {viewMore?.yaxisLow}</label></h6></div><hr></hr>
                        <div className="col-lg-3"><p align="left">X Axis Text:</p></div>
                        <div className="col-lg-9"><h6 align="left"><label>(High) {viewMore?.xaxisHigh} </label>&nbsp;&nbsp; <label>(Low)  {viewMore?.xaxisLow}</label></h6></div>
                    </div>
                </Modal>

                {activePage === "second" && <div className="row m-0 p-0">
                    <div className="col-lg-10 m-auto px-5">
                        <button onClick={() => { setActivePage("first"); store.setItem("activeTab", JSON.stringify("first")) }} className={styles.tab}>Styles</button>
                        <button onClick={() => { setActivePage("second"); store.setItem("activeTab", JSON.stringify("second")) }} className={styles.tabActive}>Styles Statement</button>
                        <button onClick={() => {setActivePage("third"); store.setItem("activeTab", JSON.stringify("third"))}} className={styles.tab}>Competencies</button>
                        <button onClick={() => {setActivePage("forth"); store.setItem("activeTab", JSON.stringify("forth"))}} className={styles.tab}>Questionaires</button>
                    </div>
                    <div className="col-lg-11 m-auto">
                        <div className={styles.card1}>
                            <button
                                onClick={() => setmodalVisible(true)}
                                className={styles.addstatement}
                            >
                                +&nbsp;&nbsp;&nbsp; ADD STYLES STATEMENT
                            </button>
                            <table style={{ marginTop: "20px" }}>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><span>Styles Name</span></td>
                                    <td className={styles.left}><span>Question</span></td>
                                    <td className={styles.left}><span>Action</span></td>
                                </tr>
                                {skillStatement.length === 0 && !getSkillStatementApi.loading && <tr>No Styles Statement available </tr>}
                                {getSkillStatementApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    skillStatement.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.skillName}</label></td>
                                        <td className={styles.left}><label>{item.question}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => {
                                            setEditSkillStatement(item);
                                            setEditSkillStatementModalVisible(true);
                                        }} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleSkillStatementDelete(item.code)} className={styles.delete}> <FaTrash /> {skillStatementToDelete === item.code && deleteSkillStatementApi.loading ? "Deleting.." : "Delete"} </button>
                                        <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleStatementDisable(item.code)} label={disableStatementStatusApi.loading ? "Disabling.." : "Enabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }

                                <tr className="mt-5"><td colSpan={3}><br></br><h5>Disabled Statements</h5></td><hr></hr></tr>
                                {statementDisabled.length === 0 && !getStatementDisabledApi.loading && <tr>No Disabled Statement available </tr>}
                                {getStatementDisabledApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    statementDisabled.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.skillName}</label></td>
                                        <td className={styles.left}><label>{item.question}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => setEditSkillStatement(item)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleSkillStatementDelete(item.code)} className={styles.delete}> <FaTrash /> {skillStatementToDelete === item.code && deleteSkillStatementApi.loading ? "Deleting.." : "Delete"} </button>
                                        <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch id="flexSwitchCheckChecked" onClick={() => handleStatementEnable(item.code)} label={enableStatementStatusApi.loading ? "Enaabling.." : "Disabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }
                            </table>
                        </div>
                    </div>
                </div>}

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    title="Update Styles Statement"
                    show={editSkillStatementModalVisible}
                    onHide={() => setEditSkillStatementModalVisible(false)}
                >
                    <Form
                        enableReinitialize
                        validationSchema={schemas.editSkillStatementSchema}
                        initialValues={{
                            code: editSkillStatement?.code,
                            skillId: editSkillStatement?.skillId,
                            skillName: editSkillStatement?.skillName,
                            question: editSkillStatement?.question,
                            statementOptionId1: editSkillStatement?.statementOptions[0].statementOptionId,
                            quadrantName1: editSkillStatement?.statementOptions[0].quadrantName,
                            quadrantId1: editSkillStatement?.statementOptions[0].quadrantId,
                            statement1: editSkillStatement?.statementOptions[0].statement,
                            statementOptionId2: editSkillStatement?.statementOptions[1].statementOptionId,
                            quadrantName2: editSkillStatement?.statementOptions[1].quadrantName,
                            quadrantId2: editSkillStatement?.statementOptions[1].quadrantId,
                            statement2: editSkillStatement?.statementOptions[1].statement,
                            statementOptionId3: editSkillStatement?.statementOptions[2].statementOptionId,
                            quadrantName3: editSkillStatement?.statementOptions[2].quadrantName,
                            quadrantId3: editSkillStatement?.statementOptions[2].quadrantId,
                            statement3: editSkillStatement?.statementOptions[2].statement,
                            statementOptionId4: editSkillStatement?.statementOptions[3].statementOptionId,
                            quadrantName4: editSkillStatement?.statementOptions[3].quadrantName,
                            quadrantId4: editSkillStatement?.statementOptions[3].quadrantId,
                            statement4: editSkillStatement?.statementOptions[3].statement,
                        }}
                        onSubmit={handleEdit2} className={styles.form}
                    >
                        <div className="row mx-4">
                            <div className="col-lg-12">
                                <TextField name={"skillName"} label={"Styles Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-12">
                                <TextField textarea name={"question"} label={"Question"} className={styles.input} />
                            </div>
                            <div className="col-lg-8">
                                <TextField name={"statement1"} label={"Statement"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <SelectField items={editSkillStatement?.statementOptions} labelField={"quadrantName"} valueField={"quadrantId"} name={"quadrantId1"} label={"Quadrant Name"} />
                            </div>
                            <div className="col-lg-8">
                                <TextField name={"statement2"} label={"Statement"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <SelectField items={editSkillStatement?.statementOptions} labelField={"quadrantName"} valueField={"quadrantId"} name={"quadrantId2"} label={"Quadrant Name"} />
                            </div>
                            <div className="col-lg-8">
                                <TextField name={"statement3"} label={"Statement"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <SelectField items={editSkillStatement?.statementOptions} labelField={"quadrantName"} valueField={"quadrantId"} name={"quadrantId3"} label={"Quadrant Name"} />
                            </div>
                            <div className="col-lg-8">
                                <TextField name={"statement4"} label={"Statement"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <SelectField items={editSkillStatement?.statementOptions} labelField={"quadrantName"} valueField={"quadrantId"} name={"quadrantId4"} label={"Quadrant Name"} />
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <SubmitButton title={editSkillStatementApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                        </div>
                    </Form>
                </Modal>  

                {activePage === "third" && <div className="row m-0 p-0">
                    <div className="col-lg-10 m-auto px-5">
                        <button onClick={() => { setActivePage("first"); store.setItem("activeTab", JSON.stringify("first")) }} className={styles.tab}>Styles</button>
                        <button onClick={() => { setActivePage("second"); store.setItem("activeTab", JSON.stringify("second")) }} className={styles.tab}>Styles Statement</button>
                        <button onClick={() => {setActivePage("third"); store.setItem("activeTab", JSON.stringify("third"))}} className={styles.tabActive}>Competencies</button>
                        <button onClick={() => {setActivePage("forth"); store.setItem("activeTab", JSON.stringify("forth"))}} className={styles.tab}>Questionaires</button>
                    </div>
                    <div className="col-lg-11 m-auto">
                        <div className={styles.card1}>
                            <button
                                onClick={() => setmodal3Visible(true)}
                                className={styles.addtalent}
                            >
                                +&nbsp;&nbsp;&nbsp; ADD NEW COMPETENCY
                            </button>
                            <table style={{ marginTop: "20px" }}>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left} style={{ width: "20%" }}><span>Name</span></td>
                                    <td className={styles.left} style={{width: "30%"}}><span>Description</span></td>
                                    <td className={styles.left} style={{ width: "50%" }}><span>Action</span></td>
                                </tr>
                                {competency.length === 0 && !getCompetencyApi.loading && <tr>No Competencys available </tr>}
                                {getCompetencyApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    competency.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label> </td>
                                        <td className={styles.left} style={{ padding: "30px 0px" }}><label>{item.description}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => setEditCompetency(item)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleDelete(item.code)} className={styles.delete}> <FaTrash /> {competencyToDelete === item.code && deleteCompetencyApi.loading ? "Deleting.." : "Delete"} </button>
                                        <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleCompetencyDisable(item.code)} label={disableCompetencyStatusApi.loading ? "Disabling.." : "Enabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }

                                <tr className="mt-5"><td colSpan={3}><br></br><h5>Disabled Competencies</h5></td><hr></hr></tr>
                                {competencyDisabled.length === 0 && !getCompetencyDisabledApi.loading && <tr className="mt-5"><td colSpan={3}>No Disabled Competency available </td> </tr>}
                                {getCompetencyDisabledApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    competencyDisabled.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label> </td>
                                        <td className={styles.left}><label>{item.description}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => setEditCompetency(item)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleDelete(item.code)} className={styles.delete}> <FaTrash /> {competencyToDelete === item.code && deleteCompetencyApi.loading ? "Deleting.." : "Delete"} </button>
                                            <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch id="flexSwitchCheckChecked" onClick={() => handleCompetencyEnable(item.code)} label={enableCompetencyStatusApi.loading ? "Enabling.." : "Disabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }
                            </table>
                        </div>
                    </div>
                </div>}

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    title=""
                    show={editCompetency?.code}
                    onHide={() => setEditCompetency(null)}
                >

                    <Form
                        enableReinitialize
                        validationSchema={schemas.editCompetencySchema}
                        initialValues={{
                            name: editCompetency?.name,
                            description: editCompetency?.description,
                            basicText: editCompetency?.basicText,
                            insufficientText: editCompetency?.insufficientText,
                            goodText: editCompetency?.goodText,
                        }}
                        onSubmit={handleEdit} className={styles.form}
                    >
                        <h4 align="left" className="mb-5">Update Competency</h4>
                        <div className="row mx-4">
                            <div className="col-lg-6">
                                <TextField name={"name"} label={"Name"} className={styles.input} />
                            </div>
                            <div className="col-lg-6">
                                <TextField textarea name={"description"} label={"Description"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <TextField textarea name={"insufficientText"} label={"Insufficent Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <TextField textarea name={"basicText"} label={"Basic Text"} className={styles.input} />
                            </div>
                            <div className="col-lg-4">
                                <TextField textarea name={"goodText"} label={"Good Text"} className={styles.input} />
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <SubmitButton title={editCompetencyApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                        </div>
                    </Form>
                </Modal>     

                {activePage === "forth" && <div className="row m-0 p-0">
                    <div className="col-lg-10 m-auto px-5">
                        <button onClick={() => { setActivePage("first"); store.setItem("activeTab", JSON.stringify("first")) }} className={styles.tab}>Styles</button>
                        <button onClick={() => { setActivePage("second"); store.setItem("activeTab", JSON.stringify("second")) }} className={styles.tab}>Styles Statement</button>
                        <button onClick={() => {setActivePage("third"); store.setItem("activeTab", JSON.stringify("third"))}} className={styles.tab}>Competencies</button>
                        <button onClick={() => {setActivePage("forth"); store.setItem("activeTab", JSON.stringify("forth"))}} className={styles.tabActive}>Questionaires</button>
                    </div>
                    <div className="col-lg-11 m-auto">
                        <div className={styles.card1}>
                            <button
                                onClick={() => setmodal2Visible(true)}
                                className={styles.addquestionaire}
                            >
                                +&nbsp;&nbsp;&nbsp; ADD NEW QUESTIONAIRE
                            </button>
                            <table style={{ marginTop: "20px" }}>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left} style={{width: "20%"}}><span>Competencies</span></td>
                                    <td className={styles.left} style={{width: "30%"}}><span>Questions</span></td>
                                    <td className={styles.left} style={{width: "50%"}}><span>Action</span></td>
                                </tr>
                                {questionaire.length === 0 && !getQuestionaireApi.loading && <tr>No Questionaires available </tr>}
                                {getQuestionaireApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    questionaire.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                            <td className={styles.left}><label>{item.competencyName}</label></td>
                                        <td className={styles.left} style={{padding: "30px 0px"}}><label>{item.question}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => {
                                            setOptions(item.questionaireOptions)
                                            setEditQuestionaire(item)
                                        }
                                            } className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleQuestionaireDelete(item.code)} className={styles.delete}> <FaTrash /> {questionaireToDelete === item.code && deleteQuestionaireApi.loading ? "Deleting.." : "Delete"} </button>
                                            <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleQuestionaireDisable(item.code)} label={disableQuestionaireStatusApi.loading ? "Disabling.." : "Enabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }

                                <tr className="mt-5"><td colSpan={3}><br></br><h5>Disabled Questionaires</h5></td><hr></hr></tr>
                                {questionaireDisabled.length === 0 && !getQuestionaireDisabledApi.loading && <tr className="mt-5"><td colSpan={3}>No Disabled Questionaire available </td> </tr>}
                                {getQuestionaireDisabledApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    questionaireDisabled.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                            <td className={styles.left}><label>{item.competencyName}</label></td>
                                        <td className={styles.left} style={{ padding: "30px 0px" }}><label>{item.question}</label></td>
                                        <td className={styles.left}><button type="submit" onClick={() => {
                                            setOptions(item.questionaireOptions)
                                            setEditQuestionaire(item)
                                        }
                                            } className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleQuestionaireDelete(item.code)} className={styles.delete}> <FaTrash /> {questionaireToDelete === item.code && deleteQuestionaireApi.loading ? "Deleting.." : "Delete"} </button>
                                            <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleQuestionaireEnable(item.code)} label={enableQuestionaireStatusApi.loading ? "Enabling.." : "Disabled"} style={{ fontSize: "20px" }} /></button></td>
                                    </tr>)
                                }

                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    title=""
                                    show={editQuestionaire?.code}
                                    onHide={() => setEditQuestionaire(null)}
                                >

                                    <Form
                                        enableReinitialize
                                        validationSchema={schemas.editQuestionaireSchema}
                                        initialValues={{
                                            question: editQuestionaire?.question,
                                            questionaireOptions: [
                                                ...options,
                                            ],
                                            competencyId: editQuestionaire?.competencyId,
                                        }}
                                        onSubmit={handleEdit3} className={styles.form}
                                    >
                                        <h4 align="left" className="mb-5">Update Questionaire</h4>
                                        <div className="row mx-4">
                                            <div className="col-lg-12 mb-3">
                                                <input value={editQuestionaire?.competencyName} disabled className={styles.input}></input>
                                                {/* <TextField name={"editQuestionaire?.competencyId"} disabled label={"Competency"} className={styles.input} /> */}
                                            </div>
                                            <div className="col-lg-12">
                                                <TextField name={"question"} label={"Question"} className={styles.input} />
                                            </div>

                                            <p style={{textAlign: "left", fontWeight: "bold"  }}>Options</p>

                                            {options.map((item, key) => ( <div className="row">
                                                <div className="col-lg-10">
                                                        <input name={"options"} label={"Statement"} className={styles.input}  value={options[key].title}
                                                            onChange={(e) => {
                                                                let newOptions = options;
                                                                newOptions[key].title = e.target.value;
                                                                 setEditQuestionaire({ ...editQuestionaire, questionaireOptions: newOptions });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button className="btn btn-danger" onClick={() => handleQuestionaireOptionDelete(item.id, item.title)}>{deleteQuestionaireOptionApi.loading && deleteQuestionaireID === item.id ? "Deleting..." : "Delete"}</button>
                                                    </div>
                                                </div>)
                                                )
                                            }  

                                            <div className="row">
                                                {[...Array(noOfOptions)].map((_, key) => (
                                                    <div className="col-lg-12 mt-4" key={key}>
                                                        <input name={"options"} label={"Statement"} className={styles.input} onBlur={(e) => {
                                                            e.target?.value && setOptions([...options, { id: 0, title: e.target.value}]);
                                                        }
                                                        } />
                                                    </div>
                                                )
                                                )}
                                                <div className="col-lg-12 mt-4">
                                                    <div className={styles.addMoreButton} onClick={() => setNoOfOptions(noOfOptions + 1)}>
                                                        <span className="btn btn-success">Add More Options +</span>
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="text-center mt-5">
                                            <SubmitButton title={editQuestionaireApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                                        </div>
                                    </Form>
                                </Modal>    
                            </table>
                        </div>
                    </div>
                </div>}                                   
            </div>
        </Wrapper>
    );
};
export default TalentStylePage;
