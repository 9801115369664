import { create } from "apisauce";
import jwtDecode from "jwt-decode";
import store from '../api/store';

const user = JSON.parse(localStorage.getItem("user"));

const token = user?.jwtToken;

if (token) {
    const jwt_Token_decoded = jwtDecode(token);
    // console.log(jwt_Token_decoded);
    // console.log(jwt_Token_decoded.exp * 1000);
    // console.log(Date.now());

    if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        store.removeItem("user");
        store.removeItem("ucat");
        window.location.reload(false);
    } 
}




const client = create({

    //https://backend.parody.be
    //baseURL: "https://pilipili-api.azurewebsites.net",
    baseURL: "http://zartech-001-site1.gtempurl.com",
    headers: {
        Accept: 'application/json',
        "Authorization": `Bearer ${token}`,
    },
});


export default client;