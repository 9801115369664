import client from "./client";  

const getUserRequests = () => client.get("/api/admin/UserRequest");
const getCompany = () => client.get("/api/admin/Company");
const getCompanyUser = () => client.get("/api/admin/User");
const getCompetencyInfo = () => client.get("/api/admin/Competency");
const getSkillInfo = () => client.get("/api/admin/Skill");
const getSkillDisabled = () => client.get("/api/admin/Skill/Disabled");
const getStatementDisabled = () => client.get("/api/admin/Statement/Disabled");
const getCompetencyDisabled = () => client.get("/api/admin/Competency/Disabled");
const getQuestionaireDisabled = () => client.get("/api/admin/Questionaire/Disabled");
const getGameDisabled = () => client.get("/api/admin/Game/Disabled");
const getSkillData = (code) => client.get(`/api/admin/Skill/${code}`);
const getQuestionaireInfo = () => client.get("/api/admin/Questionaire");
const getAssessor = () => client.get("/api/admin/Assessor");
const getSkillStatement = () => client.get("/api/admin/Statement");
const getApplications = () => client.get("/api/admin/Application");
const submitUserRequestsToken = (data) => {
    return client.post("/api/admin/UserRequest", data);
}
const getGames = () => client.get("/api/admin/Game/games");

const inviteAssessor = (data) => {
    const input = { ...data, email: data.email }
    return client.post("/api/admin/Assessor", input);
}

const addCompetency = (data) => {
    const input = { ...data, name: data.name, description: data.description, basicText: data.basicText, insufficientText: data.insufficientText, goodText: data.goodText }
    return client.post("/api/admin/Competency", input);
}

const editCompetency = (data) => {
    console.log(data);
    return client.post(`/api/admin/Competency/${data.code}`, data);
}

const deleteCompetency = (code) => client.post(`/api/admin/Competency/delete/${code}`);

const addSkill = (data) => {
    const input = {
        "name": data.name,
        "description": data.description,
        "yaxisHigh": data.yaxisHigh,
        "yaxisLow": data.yaxisLow,
        "xaxisHigh": data.xaxisHigh,
        "xaxisLow": data.xaxisLow,
        "quadrants": [            
            {
                "subText": data.subText1,
                "primaryText": data.primaryText1,
                "secondaryText": data.secondaryText1,
                "title": data.title1
            },
            {
                "subText": data.subText2,
                "primaryText": data.primaryText2,
                "secondaryText": data.secondaryText2,
                "title": data.title2
            },
            {
                "subText": data.subText3,
                "primaryText": data.primaryText3,
                "secondaryText": data.secondaryText3,
                "title": data.title3
            },
            {
                "subText": data.subText4,
                "primaryText": data.primaryText4,
                "secondaryText": data.secondaryText4,
                "title": data.title4
            }
        ]
    }
    return client.post("/api/admin/Skill", input);
}

const editSkill = (data) => {
    const input = {
        id: data.id,
        name: data.name,
        code: data.code,
        description: data.description,
        yaxisHigh: data.yaxisHigh,
        yaxisLow: data.yaxisLow,
        xaxisHigh: data.xaxisHigh,
        xaxisLow: data.xaxisLow,
        quadrants: [
            {
                id: data.id1,
                subText: data.subText1,
                primaryText: data.primaryText1,
                secondaryText: data.secondaryText1,
                title: data.title1
            },
            {
                id: data.id2,
                subText: data.subText2,
                primaryText: data.primaryText2,
                secondaryText: data.secondaryText2,
                title: data.title2
            },
            {
                id: data.id3,
                subText: data.subText3,
                primaryText: data.primaryText3,
                secondaryText: data.secondaryText3,
                title: data.title3
            },
            {
                id: data.id4,
                subText: data.subText4,
                primaryText: data.primaryText4,
                secondaryText: data.secondaryText4,
                title: data.title4
            }
        ]
    }
    return client.post(`/api/admin/Skill/${data.code}`, input);
}

const deleteSkill = (code) => client.post(`/api/admin/Skill/delete/${code}`);

const addQuestionaire = (data) => {

    return client.post("/api/admin/Questionaire", data);
}

const editQuestionaire = (data) => client.post(`/api/admin/Questionaire/${data.code}`, data);

const deleteQuestionaire = (code) => client.post(`/api/admin/Questionaire/delete/${code}`);
const deleteQuestionaireOption = (id) => client.post(`/api/admin/Questionaire/${id}/OptionId`);

const addGame = (data) => {

    const competencies = data.competency.length > 0 ? data.competency.map((item) => item.value, " ") : " ";
    const skills = data.skill.length > 0 ? data.skill.map((item) => item.value, " ") : " ";
    const formData = new FormData();
    formData.append("Name", data.name);
    formData.append("Description", data.description);
    formData.append("Competency", competencies);
    formData.append("Skill", skills);
    formData.append("Logo", data.Logo);

    // const input = {
    //     "Name": data.name,
    //     "Description": data.description,
    //     "gameLogo": formData,
    //     "competency": competencies,
    //     "skill": skills
    // };
    return client.post("/api/admin/Game", formData);
}

const updateGame = (data) => {
    const competencies = data.competency.length > 0 ? data.competency.map((item) => item.value, " ") : " ";
    const skills = data.skill.length > 0 ? data.skill.map((item) => item.value, " ") : " ";
    const formData = new FormData();
    formData.append("Name", data.name);
    formData.append("Description", data.description);
    formData.append("Competency", competencies);
    formData.append("Skill", skills);
    formData.append("Logo", data.Logo);

    return client.post(`/api/admin/Game/${data.code}`, formData);
}

const deleteGame = (code) => client.post(`/api/admin/Game/delete/${code}`);

const addSkillStatement = (data) => {
    const input = {
        skillId: data.code,
        questions: [
            {
                question: data.question,
                statementOptions: [
                    {
                        quadrantId: data.quadrant1,
                        statement: data.statement1
                    },
                    {
                        quadrantId: data.quadrant2,
                        statement: data.statement2
                    },
                    {
                        quadrantId: data.quadrant3,
                        statement: data.statement3
                    },
                    {
                        quadrantId: data.quadrant4,
                        statement: data.statement4
                    }
                ]
            }
        ]
    }

    console.log(input);

    return client.post("/api/admin/Statement", input);
}

const editSkillStatement = (data) => {
    const input = {
        skillId: data.skillId,
        question: data.question,
        statementOptions: [
            {
                statementOptionId: data.statementOptionId1,
                quadrantId: data.quadrantId1,
                statement: data.statement1
            },
            {
                statementOptionId: data.statementOptionId2,
                quadrantId: data.quadrantId2,
                statement: data.statement2
            },
            {
                statementOptionId: data.statementOptionId3,
                quadrantId: data.quadrantId3,
                statement: data.statement3
            },
            {
                statementOptionId: data.statementOptionId4,
                quadrantId: data.quadrantId4,
                statement: data.statement4
            }
        ]
    }
    console.log(input);
    return client.post(`/api/admin/Statement/${data.code}`, input);
}

const deleteSkillStatement = (code) => client.post(`/api/admin/Statement/delete/${code}`);

const deleteAssessor = (code) => client.post(`/api/admin/Assessor/${code}`);

const deleteCompany = (code) => client.post(`/api/admin/Company/${code}`);

const assignAssessor = (data) => {

    //const input = {assessorId: data.assessorId };

    console.log(data.code);

    return client.post(`/api/admin/Application/${data.code}/AssignMultipleAssessor`, data)
};

const updateAssessor = (data) => {

    //const input = {...data, assessorId: data.assessorId};

    return client.post(`/api/admin/Application/${data.code}/UpdateAssessor`, data)
};

const deleteAssignedAssessor = (data) => {
    console.log(data.assessorId);
    return client.post(`/api/admin/Application/${data.code}/DeleteAssessor/${data.assessorId}`)
}

const forgotPassword = (data) => client.post("/api/admin/Administrator/ForgotPassword", data);

const resetPassword = (data) => client.post("/api/admin/Administrator/ResetPassword", data);

const deleteUserRequest = (email) => client.post(`/api/admin/UserRequest/${email}`);

const deleteGameSkill = (id) => client.post(`/api/admin/Game/${id}/GameSkillId`);

const deleteGameCompetency = (id) => client.post(`/api/admin/Game/${id}/GameCompetencyId`);

const disableSkillStatus = (code) => client.post(`/api/admin/Skill/${code}/status/false`);
const enableSkillStatus = (code) => client.post(`/api/admin/Skill/${code}/status/true`);

const disableStatementStatus = (code) => client.post(`/api/admin/Statement/${code}/status/false`);
const enableStatementStatus = (code) => client.post(`/api/admin/Statement/${code}/status/true`);

const disableCompetencyStatus = (code) => client.post(`/api/admin/Competency/${code}/status/false`);
const enableCompetencyStatus = (code) => client.post(`/api/admin/Competency/${code}/status/true`);

const disableQuestionaireStatus = (code) => client.post(`/api/admin/Questionaire/${code}/status/false`);
const enableQuestionaireStatus = (code) => client.post(`/api/admin/Questionaire/${code}/status/true`);

const disableGameStatus = (code) => client.post(`/api/admin/Game/${code}/status/false`);
const enableGameStatus = (code) => client.post(`/api/admin/Game/${code}/status/true`);

const getApplicationDetail = (code) => client.get(`/api/admin/Application/${code}`);

const filteredApplication = (id) => client.get(`/api/admin/Application`, id);

const deleteCompanyUser = (email) => client.post(`/api/admin/User/delete/${email}`);

const deleteApplication = (code) => client.post(`/api/admin/Application/DeleteApplication/${code}`);

const updateApplication = (data) => {   
    console.log(data);
    const input = {
        lst: data.games,
        timeOfEvent: data.timeOfEvent,
        dateOfEvent: data.dateOfEvent
    }
    //return client.post(`/api/CompanyProfile/UpdateApplication/${data.code}`, input);
    return client.post(`/api/admin/Application/UpdateApplication/${data.code}`, input);
};


const getSkillReport = (data) =>
    client.get(`/api/admin/Application/Report/${data.code}/skill/${data.batchId}`);
//client.get(`/api/AssessorProfile/Report/${code}/skill`);

const getCompetencyReport = (data) =>
    client.get(`/api/admin/Application/Report/${data.code}/competency/${data.batchId}`);
//client.get(`/api/AssessorProfile/Report/${code}/competency`);

const getAssessedBatch = (code) => client.get(`/api/Batch/getassessed/${code}`);

const adminApis = {
    addGame,
    getGames,
    addSkill,
    editSkill,
    deleteSkill,
    updateGame,
    deleteGame,
    getSkillInfo,
    getSkillData,
    addCompetency,
    editCompetency,
    deleteCompetency,
    inviteAssessor,
    addQuestionaire,
    getUserRequests,
    getCompetencyInfo,
    getQuestionaireInfo,
    getAssessor,
    deleteAssessor,
    submitUserRequestsToken,
    getCompany,
    deleteCompany,
    addSkillStatement,
    editSkillStatement,
    getSkillStatement,
    deleteSkillStatement,
    editQuestionaire,
    getApplications,
    assignAssessor,
    deleteQuestionaire,
    getSkillReport,
    getCompetencyReport,
    forgotPassword,
    resetPassword,
    deleteUserRequest,
    deleteQuestionaireOption,
    deleteGameSkill,
    deleteGameCompetency,
    getSkillDisabled,
    disableSkillStatus,
    enableSkillStatus,
    getStatementDisabled,
    disableStatementStatus,
    enableStatementStatus,
    getCompetencyDisabled,
    disableCompetencyStatus,
    enableCompetencyStatus,
    getQuestionaireDisabled,
    disableQuestionaireStatus,
    enableQuestionaireStatus,
    getGameDisabled,
    disableGameStatus,
    enableGameStatus,
    getApplicationDetail,
    deleteAssignedAssessor,
    updateAssessor,
    getCompanyUser,
    deleteCompanyUser,
    deleteApplication,
    updateApplication,
    filteredApplication,
    getAssessedBatch,
}

export default adminApis;