import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router";

import useApi from "../../hooks/useApi";
import store from "../../api/store";
import adminApis from "../../api/admin";
import Wrapper from "../../layouts/Wrapper";
import people from "../../images/people.svg";
import recruit from "../../images/recruit.svg";
import styles from "../../styles/Dashboard.module.css";
import routes from "../../routes";

const DashboardPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));

    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);
    const requestsApi = useApi(adminApis.getUserRequests);

    useEffect(() => {
        getUserRequests();
    },[]);

    const getUserRequests = async () => {
        const res = await requestsApi.request();
        if(res.ok) setRequests(res.data.data);
    }

    const getApplicationsApi = useApi(adminApis.getApplications);
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = async () => {
        const res = await getApplicationsApi.request();
        if (res.ok) setApplications(res.data.data);
    }
    

    return (
        <Wrapper activePage={routes.DashboardPage}>              
            <div id="testId">
                <h2 align="center">Dashboard</h2>
                <div className={styles.card}>
                    <img src={people} className={styles.people} alt="people"></img>
                    <img src={recruit} alt=""></img>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-lg-6">
                        <div className={styles.card1}>
                            <ul>
                                <li> <h4 align="left"><b>User Request</b></h4> </li>
                                <li style={{ cursor: "pointer" }} onClick={() => navigate(routes.UserRequestPage)}> <h6 align="right">View all</h6> </li>
                            </ul>    
                            <table>
                                <thead>
                                    <td>Email Address</td>
                                    <td>Phone Number</td>
                                </thead>
                                <tbody>
                                    { requests.length === 0 && !requestsApi.loading && <tr>No user request available </tr>}
                                    {requestsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true}  /></tr>:
                                    requests.map((item, key) => <tr key={key}>
                                            <td className={styles.left}>{item.email}</td>
                                            <td className={styles.left}>{item.phoneNumber}</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={styles.card2}>
                            <ul>
                                <li> <h4 align="left"><b>Applications</b></h4> </li>
                                <li> <h6 align="right" style={{ cursor: "pointer" }} onClick={() => navigate(routes.ApplicationPage)}>View all</h6> </li>
                            </ul>
                            <table>
                                <thead>
                                    <td>Company Name</td>
                                    <td>Games</td>
                                    <td>Assessor</td>
                                </thead>
                                <tbody>
                                    {applications.length === 0 && !getApplicationsApi.loading && <tr>No Application available </tr>}
                                    {getApplicationsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                        applications.items?.slice(0, 5).map((item, key) => <tr key={key}>
                                            <td className={styles.left}><span>{item.companyName}</span></td>
                                            <td>{item.applicationGames[0].name}</td>
                                            <td>{item.assessorName}</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default DashboardPage;