import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import mask from "../../images/mask.svg";
import Wrapper from "../../layouts/Wrapper";
import styles from "../../styles/Report.module.css";
import routes from "../../routes";
import useApi from "../../hooks/useApi";
import cadminApis from "../../api/cadmin";
import { PuffLoader } from "react-spinners";
import { FaDownload, FaArrowLeft } from 'react-icons/fa';
import SkillReport from "../../components/SkillReport";
import CompetencyReport from "../../components/CompetencyReport";
import GenericPdfDownloader from "../../components/ReportDownload";
import { Button } from "react-bootstrap";


const ReportPage = () => {

    const [activePage, setActivePage] = useState(["first"]);


    const [activeReport, setActiveReport] = useState();
    const getApplicationsApi = useApi(cadminApis.getApplications);
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = async () => {
        const res = await getApplicationsApi.request();
        if (res.status === 200) {
            setApplications(res.data.data.items);
        }
    }

    const [batchId, setBatchId] = useState([]);
    const [activeApplicationCode, setActiveApplicationCode] = useState();
    const [activeApplication, setActiveApplication] = useState();
    const getAssessedBatchApi = useApi(cadminApis.getAssessedBatch);

    const handleActiveApplication = async (data) => {
        const res = await getAssessedBatchApi.request(data.code);
        if (res) {
            setActiveApplication(res.data.data);
            setModalShow(true);
        }
    }

    const getSkillReportApi = useApi(cadminApis.getSkillReport);
    const [skillReport, setSkillReport] = useState([]);
    const getCompetencyReportApi = useApi(cadminApis.getCompetencyReport);
    const [competencyReport, setCompetencyReport] = useState([]);


    const handleGetReport = async (e) => {
        e.preventDefault();
        console.log(batchId.batchId);
        const res = await getSkillReportApi.request({ batchId: batchId.batchId, code: activeApplicationCode.code });
        const res1 = await getCompetencyReportApi.request({ batchId: batchId.batchId, code: activeApplicationCode.code });

        if (res.ok && res1.ok) {
            setSkillReport(res.data.data);
            setCompetencyReport(res1.data.data);

            //setActiveReport(candidateName);
            setActivePage("second");
        }
    }

    // const handleAllReport = async (code, candidateName) => {
    //     const res = await getSkillReportApi.request(code);
    //     const res1 = await getCompetencyReportApi.request(code);

    //     if (res.ok && res1.ok) {
    //         setSkillReport(res.data.data);
    //         setCompetencyReport(res.data.data);

    //         setActiveReport(candidateName);
    //         setActivePage("third");
    //         //setModalShow(true);
    //     }

    // };

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            {activePage == "first" && <Wrapper activePage={routes.CompanyReport}><div className="row m-0 p-0">
                <div className="col-lg-11 m-auto">
                    <h2 align="center">Applications</h2>
                    {/* <form style={{ width: "30%", float: "right" }}><input type="text" name="search" className="mt-3 form-control" placeholder="Search" width="200px"></input></form> */}
                </div>
                <div className="col-lg-12 m-auto">
                    <div className={styles.card}>
                        <table className="table-bordered" width="100%">
                            <thead><td style={{ padding: "15px 0px" }}>Company Name</td><td>Date</td><td>No of Games</td><td>Actions</td>
                            </thead>
                            {applications.length === 0 && !getApplicationsApi.loading && <tr>No Applications available </tr>}
                            {getApplicationsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                applications.map((item, key) => <tr key={key}>

                                    <td>{item.companyName}</td>
                                    <td>{item.dateOfEvent}</td>
                                    <td>{item.applicationGames.length}</td>
                                    {/* <td className={styles.right} style={{ padding: "15px 0px" }}><button onClick={() =>  {setActiveApplication(item.code); setActivePage("second")}} className={styles.success}> VIEW APPLICATION DETAILS</button></td> */}
                                    <td className={styles.right} style={{ padding: "15px 0px" }}><button onClick={() => { handleActiveApplication(item); setActiveApplicationCode(item) }} className={styles.success}> VIEW APPLICATION DETAILS</button></td>
                                </tr>)
                            }
                        </table>
                    </div>
                    <Modal
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        id="testId"
                        centered
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row col-md-12 text-center pb-5 px-3">
                                <h4 className="mb-4">Select A Batch To View Report</h4>
                                <form method="post" onSubmit={handleGetReport}>
                                    <select className="form-control mb-4" onChange={(e) => setBatchId({ ...batchId, batchId: e.target.value })}>
                                        <option>Select Batch</option>
                                        {activeApplication?.map(item => <option value={item.id}>{item.name}</option>)}
                                    </select>
                                    <button type="submit" className={styles.sendCode}>{getSkillReportApi.loading ? "Loading..." : "Continue"}</button>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* <div className="text-end"> <button className="btn btn-light">1</button> &nbsp; <button className="btn btn-light">2</button> &nbsp; <button className="btn btn-light">3</button>  &nbsp; .... &nbsp; <button className="btn btn-light">20</button></div> */}
                </div>
            </div></Wrapper>}

            {activePage == "second" && <Wrapper activePage={routes.CompanyReport}>
                <Button
                    variant="contained"
                    onClick={() => setActivePage("first")}
                ><FaArrowLeft /> Go Back </Button>
                <div className="row m-0 p-0">
                    <div className="col-lg-12 m-auto">
                        <h2 align="center">Assessment Reports</h2>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className={styles.card} style={{ float: "left", width: "45%" }}>
                            <table width="100%" style={{ fontSize: "20px" }}>
                                <tr> <td>Company Name: </td><td>{activeApplicationCode.companyName}</td> </tr>
                                <tr> <td>No of Candidates: </td><td>{competencyReport.length}</td> </tr>
                                <tr> <td>No of Games: </td><td>{activeApplicationCode.applicationGames.length}</td> </tr>
                            </table>
                        </div>

                        <div className={styles.card} style={{ float: "right", width: "45%" }}>
                            <table width="100%" style={{ fontSize: "20px" }}>
                                {/* <tr> <td>Assessment Date: </td><td>{activeApplication.dateOfEvent}</td> </tr>
                        <tr> <td>Assessment Time: </td><td>{activeApplication.timeOfEvent}</td> </tr><br></br> */}
                                {/* <tr className="text-center"><td colSpan={2}><button onClick={() => handleAllReport(activeApplication.code)} className={styles.success} style={{fontSize: "20px"}}><FaDownload /> VIEW All REPORT</button></td></tr> */}
                            </table>
                        </div>

                    </div>
                    <div className="col-lg-12 m-auto">
                        <div className={styles.card}>
                            <table className="table-bordered" width="100%">
                                <thead><td style={{ padding: "15px 0px" }}>Candidates Name</td><td>Actions</td>
                                </thead>
                                {competencyReport.map((candidate, key) => <tr key={key}> <td>{candidate.candidateName} </td>
                                    <td className={styles.right} style={{ padding: "15px 0px" }}><button onClick={() => { setActiveReport(candidate.candidateName); setActivePage("third") }} className={styles.success}> VIEW CANDIDATE REPORT</button></td>
                                </tr>)}
                            </table>
                        </div>
                    </div>
                </div></Wrapper>}
            {activePage == "third" && <div>
                <Button
                    variant="contained"
                    onClick={() => setActivePage("second")}
                ><FaArrowLeft /> Go Back </Button>
                <div className="row m-0 p-0">
                    <div className="col-lg-7 px-5"><h2 align="right">Styles Reports</h2></div>
                    <div className="col-lg-5 text-end">
                        <Button
                            style={{ backgroundColor: "#3E3B4E", padding: "10px 40px", borderRadius: "30px", color: "#FFF" }}
                            variant="contained"
                            onClick={() => window.print()}
                        ><FaDownload /> Download Report</Button>
                        {/* <GenericPdfDownloader
                            downloadFileName="ReportDownload"
                            rootElementId="testId"
                        /> */}
                    </div>
                </div>

                <SkillReport reports={skillReport} applicationDetails={activeApplicationCode} candidateName={activeReport} />

                <br></br><hr></hr><br></br>

                <div className="row m-0 p-0">
                    <div className="col-lg-8 px-5"><h2 align="right">Competency Reports</h2></div>
                </div>

                <CompetencyReport reports={competencyReport} applicationDetails={activeApplicationCode} candidateName={activeReport} />
            </div>}
        </>
    )
};

export default ReportPage;