import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import routes from './routes'
import store from './api/store'

const AuthenticatedRoutes = () => {
    const user = store.getItem("user");

    return user ? <Outlet /> : <Navigate to={routes.LandingPage} />
}

export default AuthenticatedRoutes