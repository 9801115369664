import React, { useEffect, useState } from "react";
import routes from "../../routes";
import { Link } from 'react-router-dom';

import adminApis from "../../api/admin";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import { Button, Modal } from "react-bootstrap";
import styles from "../../styles/Assessors.module.css";
import logo from "../../images/LogoParodyWhite.svg";
import NavItem from "../../components/NavItem";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import Wrapper from "../../layouts/Wrapper";

// function MyVerticallyCenteredModal(props) {


//     const [email, setEmail] = useState({})
//     const inviteAssessorApi = useApi(adminApis.inviteAssessor);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         inviteAssessorApi.setLoading(true);
        
//         const result = await inviteAssessorApi.request(email);

//         console.log(result)
//         if (result.ok) {
//             helpers.toaster.success("Invitation Sent Successfully");
//             window.location.reload(false);
//         }

//         inviteAssessorApi.setLoading(false);
        
//         //return navigate(routes.);
//     }

//     return (
//         <Modal
//             {...props}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title id="contained-modal-title-vcenter">

//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="text-center">
//                 <form method="post" onSubmit={handleSubmit} className={styles.form}>
//                     <h4 align="left" className="mb-5">Invite Assessor</h4>
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <label>Email</label>
//                             <input type="email" id="" className="form-control" placeholder="johnfoer@gmail.com" onChange={(e) => setEmail({ ...email, email: e.target.value })}></input>
//                         </div>
//                     </div>
//                     <div className="text-center mt-5">
//                         <button type="submit" className={styles.sendCode}>{inviteAssessorApi.loading ? "Sending Link..." : "Send Link"}</button>
//                     </div>
//                 </form>
//             </Modal.Body>
//             <Modal.Footer>
//             </Modal.Footer>
//         </Modal>
//     );
// }

const AssessorsPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));

    const [modalShow, setModalShow] = React.useState(false);
    const [assessorToDelete, setAssessorToDelete] = useState();
    const deleteAssessorApi = useApi(adminApis.deleteAssessor);

    const getAssessorApi = useApi(adminApis.getAssessor);
    const [assessor, setAssessor] = useState([]);

    useEffect(() => {
        getAssessor();
    }, []);

    const getAssessor = async () => {
        const res = await getAssessorApi.request();
        if (res.ok) setAssessor(res.data.data);
    }

    const [email, setEmail] = useState({})
    const inviteAssessorApi = useApi(adminApis.inviteAssessor);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // inviteAssessorApi.setLoading(true);

        const result = await inviteAssessorApi.request(email);

        console.log(result);
        if (result.ok) {
            helpers.toaster.success("Invitation Sent Successfully");
            window.location.reload(false);
        }
        // inviteAssessorApi.setLoading(false);
    }

    const handleDelete = async (code) => {
        setAssessorToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Assessor?");
        if (del) {
            const res = await deleteAssessorApi.request(code);
            if (res.status === 200) {
                helpers.toaster.success("Success Deleted");
                //window.location.reload(false);
            }
            else {
                helpers.toaster.error(res.data.responseMessage);
                //window.location.reload(false);
            }
        }
    }


    return (
        <Wrapper activePage={routes.AssessorsPage}>
            <div className="row m-0 p-0">
                <div className="col-lg-7 text-end">
                    <h2>Assessors</h2>
                </div>

                <div className="col-lg-5 text-end"><button onClick={() => setModalShow(true)} className={styles.invite}>+&nbsp;&nbsp;&nbsp;&nbsp; INVITE ASSESSOR</button></div>
            </div>  

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <form method="post" onSubmit={handleSubmit} className={styles.form}>
                        <h4 align="left" className="mb-5">Invite Assessor</h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Email</label>
                                <input type="email" id="" className="form-control" placeholder="johnfoer@gmail.com" onChange={(e) => setEmail({ ...email, email: e.target.value })}></input>
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <button type="submit" className={styles.sendCode}>{inviteAssessorApi.loading ? "Sending Link..." : "Send Link"}</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            
            <div className="row m-0 p-0">
                <div className="col-lg-9 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <tr style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><span>Email Address</span></td>
                                <td className={styles.left}><span>Full Name</span></td>
                                <td className={styles.left}><span>Phone Number</span></td>
                                <td className={styles.left}><span>Company Name</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            {assessor.length === 0 && !getAssessorApi.loading && <tr>No Assessor Available </tr>}
                            {getAssessorApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                assessor.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><label>{item.email}</label></td>
                                    <td className={styles.left}><label>{item.firstName} {item.lastName}</label></td>
                                    <td className={styles.left}><label>{item.phoneNumber}</label></td>
                                    <td className={styles.left}><label>{item.companyName}</label></td>
                                    <td className={styles.left}>
                                        <button type="submit" onClick={() => handleDelete(item.code)} className={styles.danger}> <FaTrash /> {assessorToDelete === item.code && deleteAssessorApi.loading ? "Deleting.." : "Delete"} </button>
                                    </td>
                                </tr>)
                            }
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default AssessorsPage;