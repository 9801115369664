import { toast } from "react-toastify";

const getInitialValuesFromSchema = (schema) => Object.keys(schema.fields).reduce(
    (object, key) => ({ ...object, [key]: "" }),
    {}
);

const toaster = {
    success: (message) => toast.success(message),
    error: (message) => toast.error(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
};

const getError = (error) => {
    return typeof error === "boolean" ? "An error occured, please try again." : error
}

const getRandNumber = () => Math.floor(100000 + Math.random() * 900000);

const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

export const helpers = {
    toaster,
    getError,
    renameKeys,
    getRandNumber,
    getInitialValuesFromSchema,
}