import client from "./client";

const accessCode = (data) => {
    
    const input = {
        "email": data.email,
        "description": "none",
        "phoneNumer": data.mobile,
        "firstName": data.first_name,
        "lastName": data.last_name
    }
    return client.post("/api/CompanyProfile/RequestAccess", input);
}

const submitAccessCode = (data) => {
    return client.post("/api/CompanyProfile/Verification", data);
}

const updateProfile = (data) => {
    const { image } = data;
    
    const formData = new FormData();
    for (let field of Object.keys(data)){
        formData.append(field, data[field]);
    }
    
    formData.delete("image");
    formData.append("image", {
        type: "image/jpeg",
        ...image,
    });

    return client.post(`/api/companyprofile/${data.code}/completecompanyprofile/`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
          },
        }
    );
}
const getGames = () => client.get("/api/CompanyProfile/Games");
const getSkills = () => client.get("/api/CompanyProfile/Skills");
const getTalents = () => client.get("/api/CompanyProfile/Competencies");
const getFilteredGames = (filters) => client.post("/api/CompanyProfile/FilterGame", filters);
const createApplication = (data) => {

    const input = {
        lst: data.games,
        timeOfEvent: data.timeOfEvent,
        dateOfEvent: data.dateOfEvent
      }

    // const input = {
    //     "lst": [
    //         {
    //             "game": 1,
    //             "competency": [
    //                 1
    //             ],
    //             "skill": [
    //                 1
    //             ]
    //         }
    //     ],
    //     "timeOfEvent": {
    //         "ticks": 1,
    //         "days": 1,
    //         "hours": 1,
    //         "milliseconds": 10,
    //         "minutes": 10,
    //         "seconds": 10
    //     },
    //     "dateOfEvent": "2022-06-19T19:45:48.567Z"
    // }

      return client.post("/api/CompanyProfile/CreateApplication", input);
}

const updateUser = (data) => {

    return client.post(`/api/CompanyProfile/UpdateUser`, data);
};

const uploadCompanyImage = (data) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const companyLogo = data;
    
    const formData = new FormData();
    formData.append("image", companyLogo);


    return client.post(`/api/CompanyProfile/UploadCompanyImage/${user.companyCode}`, formData);
};

const addNewUser = (data) => {
  return client.post("/api/CompanyProfile/CreateUser", data);
}

const forgotPassword = (data) => client.post("/api/CompanyProfile/ForgotPassword", data);

const resetPassword = (data) => client.post("/api/CompanyProfile/ResetPassword", data);

const getSkillReport = (data) =>
    client.get(`/api/CompanyProfile/Report/${data.code}/skill/${data.batchId}`);
//client.get(`/api/AssessorProfile/Report/${code}/skill`);

const getCompetencyReport = (data) =>
    client.get(`/api/CompanyProfile/Report/${data.code}/competency/${data.batchId}`);
  //client.get(`/api/AssessorProfile/Report/${code}/competency`);


// const getSkillReport = (code) => client.get(`/api/CompanyProfile/Report/${code}/skill`);
// const getCompetencyReport = (code) => client.get(`/api/CompanyProfile/Report/${code}/competency`);


const getApplications = () => client.get("/api/CompanyProfile/Applications");

const getApplicationDetail = (code) =>  client.get(`/api/admin/Application/${code}`);

const deleteCompanyApplication = (code) => client.post(`/api/CompanyProfile/DeleteApplication/${code}`);

const updateCompanyApplication = (data) => {
    console.log(data);
    const input = {
        lst: data.games,
        timeOfEvent: data.timeOfEvent,
        dateOfEvent: data.dateOfEvent
    }

    //return client.post("/api/CompanyProfile/CreateApplication", input);
    return client.post(`/api/CompanyProfile/UpdateApplication/${data.code}`, input);
};

const getAssessedBatch = (code) => client.get(`/api/Batch/getassessed/${code}`);

const cadmin = {
    getGames,
    getSkills,
    addNewUser,
    accessCode,
    getTalents,
    updateProfile,
    submitAccessCode,
    createApplication,
    forgotPassword,
    resetPassword,
    getFilteredGames,
    updateUser,
    uploadCompanyImage,
    getApplicationDetail,
    getCompetencyReport,
    getSkillReport,
    getApplications,
    deleteCompanyApplication,
    updateCompanyApplication,
    getAssessedBatch,
}

export default cadmin