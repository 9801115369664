import React, { useEffect } from 'react'
import { useFormikContext } from "formik";
import ShowMoreText from "react-show-more-text";
import gamesIcon from "../../images/gamesIcon.png";
import styles from "../../styles/Assessment.module.css";

export default function GameField({ game, name }) {
    const { setFieldTouched, setFieldValue, values } = useFormikContext();
    const getCurrentGame = () => values[name].find((obj) => obj.game === game.id);

    useEffect(() => {
        setFieldTouched(name);
    }, []);

    
    return ( 
        <div className={styles.cardGame}>
            <div className="row m-3">
                <div className="col-4">
                    <label htmlFor={game.code}>
                        <div className={styles.cardBox}>
                            <div className={styles.cardHeader}>
                                <input type="checkbox" id={game.code} onClick={(e) => {

                                    // checked, add the current clicked game
                                    if(e.target.checked) return setFieldValue(name, [...values[name], {game: game.id, competency: [], skill: []}]);

                                    // unchecked, remove the current clicked game
                                    setFieldValue(name, values[name].filter((obj) => obj.game !== game.id));
                                }}/>
                                <h4><b>{game.name}</b></h4>
                            </div>
                            {game.gameLogo === null ? <img src={gamesIcon} width="100%" className="mt-3 mb-3" alt=""></img> : <img src={game.gameLogo} width="100%" className="mt-3 mb-3" alt=""></img>}
                            <p>{game.description}</p>
                        </div>
                    </label>
                </div>
                <div className="col-8" style={{ display: "flex" }}>
                    <div className={styles.row}>
                        <label className={styles.label}>Styles</label>
                        {(game.skills || game.skill).map((skill) => (    
                            <label key={`${game.id}-${skill.skillId}`} className={styles.cardII} htmlFor={`${game.id}-${skill.skillId}`}>
                                <input type="checkbox" id={`${game.id}-${skill.skillId}`} disabled={!getCurrentGame()} onChange={(e) => {
                                    let currentGame = getCurrentGame();
                                    const filteredGames = values[name].filter((obj) => obj.game !== game.id);

                                    // Checked, add selected skill
                                    if(e.target.checked) {
                                        currentGame = {...currentGame, skill: [...currentGame.skill, skill.skillId]};
                                        return setFieldValue(name, [...filteredGames, currentGame]);
                                    }
                                    
                                    // Unchecked, remove the skill clicked
                                    currentGame = {...currentGame, skill: currentGame.skill.filter(obj => obj !== skill.skillId)};
                                    setFieldValue(name, [...filteredGames, currentGame]);
                                }} />
                                <p>{skill.name}</p>
                            </label>
                        ))}
                    </div>
                    <div className={styles.row}>
                        <label className={styles.label}>Competencies</label>
                        {(game.competency || game.competencies).map((talent) => (
                            <label key={`${game.id}-${talent.competencyId}`} className={styles.cardIII} htmlFor={`${game.id}-${talent.competencyId}`}>
                                <input type="checkbox" id={`${game.id}-${talent.competencyId}`} disabled={!getCurrentGame()} onChange={(e) => {
                                    let currentGame = getCurrentGame();
                                    const filteredGames = values[name].filter((obj) => obj.game !== game.id);

                                    // Checked, add selected talent
                                    if(e.target.checked) {
                                        currentGame = {...currentGame, competency: [...currentGame.competency, talent.competencyId]};
                                        return setFieldValue(name, [...filteredGames, currentGame]);
                                    }
                                    
                                    // Unchecked, remove the talent clicked
                                    currentGame = {...currentGame, competency: currentGame.competency.filter(obj => obj !== talent.competencyId)};
                                    setFieldValue(name, [...filteredGames, currentGame]);
                                }} />
                                <p>{talent.name}</p>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
