import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import routes from "../../routes";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import schemas from "../../utils/schemas";
import cadminApis from "../../api/cadmin";
import { Link } from 'react-router-dom';
import homeBg from "../../images/img1.svg";
import Loader from "../../components/Loader";
import { helpers } from "../../utils/helpers";
import logo from "../../images/LogoParody.svg";
import recruit from "../../images/recruit.svg";
import styles from "../../styles/Login.module.css";
import { Form, SubmitButton, TextField } from "../../components/form";
import client from "../../api/client";

const CompanyResetPassword = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get("accessCode");
    const email = searchParams.get("email");

    const userResetPassApi = useApi(cadminApis.resetPassword);
    const [resetPass, setResetPass] = useState([]);

    const [statusMessage, setStatusMessage] = useState([]);

    const handleSubmit = async (data) => {
        const res = await userResetPassApi.request(data);
        if (res.ok) {
            helpers.toaster.success("Request Sent succcessfully");
            setStatusMessage("Your password has been reset Successfully.");
            return statusMessage;
        }
    }


    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////
    
    return (
        <div>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content2}>
                            <h2 className="mb-5 text-center">Reset Your Password</h2>
                            <h6 style={{ color: "green", textAlign: "center", marginTop: "-20px", fontWeight: "bold" }}>{statusMessage}</h6>
                            <Form
                                enableReinitialize
                                validationSchema={schemas.resetPasswordSchema}
                                initialValues={{
                                    email: email,
                                    token: token,
                                    password: "",
                                    confirmPassword: "",
                                }}
                                onSubmit={handleSubmit} className={styles.form1}
                            >
                                <div className="row">
                                    <div className="col-lg-12">
                                        <TextField name={"email"} disabled label={"Email Address"} className={styles.input} />
                                    </div>
                                    <div className="col-lg-12">
                                        <TextField name={"token"} disabled label={"Token"} className={styles.input} />
                                    </div>
                                    <div className="col-lg-12">
                                        <TextField name={"password"} label={"New Password"} secureEntry autoFocus className={styles.input} />
                                    </div>
                                    <div className="col-lg-12">
                                        <TextField name={"confirmPassword"} label={"Confirm new password"} secureEntry className={styles.input} />
                                    </div>
                                </div>
                                <div className="text-center mt-3 mb-5">
                                    <SubmitButton title={userResetPassApi.loading ? "RESETTING PASSWORD..." : "RESET PASSWORD"} className={styles.sendCode} />
                                </div>
                            </Form>
                            <p style={{ textAlign: "center", marginTop: "-20px" }}>Go Back To <Link style={{ color: "#000" }} to="../cadmin/login">Login Page</Link></p>
                        </div>
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0 text-center">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="" className={styles.logo1} />
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="sideImage" className={styles.image1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CompanyResetPassword;