import React from 'react'

export function DashboardIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24.375 24.375"> <path id="ic_poll_24px" d="M24.666,3H5.708A2.716,2.716,0,0,0,3,5.708V24.666a2.716,2.716,0,0,0,2.708,2.708H24.666a2.716,2.716,0,0,0,2.708-2.708V5.708A2.716,2.716,0,0,0,24.666,3ZM11.125,21.958H8.417V12.479h2.708Zm5.417,0H13.833V8.417h2.708Zm5.417,0H19.25V16.542h2.708Z" transform="translate(-3 -3)" fill={color} /> </svg>
    )
}

export function AssesorIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 20.55"><path id="ic_local_library_24px" d="M12,11.55A13.153,13.153,0,0,0,3,8V19a13.153,13.153,0,0,1,9,3.55A13.2,13.2,0,0,1,21,19V8A13.153,13.153,0,0,0,12,11.55ZM12,8A3,3,0,1,0,9,5,3,3,0,0,0,12,8Z" transform="translate(-3 -2)" fill={color} /></svg>
    )
}

export function GeneralIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24.375 24.375"><path id="ic_poll_24px" d="M24.666,3H5.708A2.716,2.716,0,0,0,3,5.708V24.666a2.716,2.716,0,0,0,2.708,2.708H24.666a2.716,2.716,0,0,0,2.708-2.708V5.708A2.716,2.716,0,0,0,24.666,3ZM11.125,21.958H8.417V12.479h2.708Zm5.417,0H13.833V8.417h2.708Zm5.417,0H19.25V16.542h2.708Z" transform="translate(-3 -3)" fill={color} /></svg>
    )
}

export function UserRequestIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 27.723 20.162"><path id="ic_person_add_24px" d="M18.642,14.081A5.04,5.04,0,1,0,13.6,9.04,5.039,5.039,0,0,0,18.642,14.081ZM7.3,11.561V7.78H4.78v3.78H1v2.52H4.78v3.78H7.3v-3.78h3.78v-2.52ZM18.642,16.6c-3.365,0-10.081,1.689-10.081,5.04v2.52H28.723v-2.52C28.723,18.29,22.006,16.6,18.642,16.6Z" transform="translate(-1 -4)" fill={color} /></svg>
    )
}

export function RequestIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 20"><path id="ic_assignment_returned_24px" d="M19,3H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3Zm0,15L7,13h3V9h4v4h3Z" transform="translate(-3 -1)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /></svg>
    )
}

export function GamesIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path id="ic_games_24px" d="M15,7.5V2H9V7.5l3,3ZM7.5,9H2v6H7.5l3-3ZM9,16.5V22h6V16.5l-3-3ZM16.5,9l-3,3,3,3H22V9Z" transform="translate(-2 -2)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /></svg>
    )
}

export function ApplicationIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path id="ic_games_24px" d="M15,7.5V2H9V7.5l3,3ZM7.5,9H2v6H7.5l3-3ZM9,16.5V22h6V16.5l-3-3ZM16.5,9l-3,3,3,3H22V9Z" transform="translate(-2 -2)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /></svg>
    )
}

export function SignOutIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path id="ic_exit_to_app_24px" d="M10.09,15.59,11.5,17l5-5-5-5L10.09,8.41,12.67,11H3v2h9.67ZM19,3H5A2,2,0,0,0,3,5V9H5V5H19V19H5V15H3v4a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z" transform="translate(-3 -3)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /></svg>
    )
}

export function TalentSkillIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g id="Group_641" data-name="Group 641" transform="translate(-28 -208)"><circle id="Ellipse_32" data-name="Ellipse 32" cx="12" cy="12" r="12" transform="translate(28 208)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /><text id="_" data-name="?" transform="translate(40 226)" fill="#3e3b4e" fontSize="16" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="-3.988" y="0">?</tspan></text></g></svg>
    )
}

export function TalentStyleIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g id="Group_641" data-name="Group 641" transform="translate(-28 -208)"><circle id="Ellipse_32" data-name="Ellipse 32" cx="12" cy="12" r="12" transform="translate(28 208)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /><text id="_" data-name="?" transform="translate(40 226)" fill="#3e3b4e" fontSize="16" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="-3.988" y="0">?</tspan></text></g></svg>
    )
}

export function ReportIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 20"><path id="ic_assignment_24px" d="M19,3H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3Zm2,14H7V15h7Zm3-4H7V11H17Zm0-4H7V7H17Z" transform="translate(-3 -1)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /></svg>
    )
}

export function ProfileIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19.787 19.787"><path id="ic_person_24px" d="M13.894,13.894A4.947,4.947,0,1,0,8.947,8.947,4.945,4.945,0,0,0,13.894,13.894Zm0,2.473c-3.3,0-9.894,1.657-9.894,4.947v2.473H23.787V21.314C23.787,18.024,17.2,16.367,13.894,16.367Z" transform="translate(-4 -4)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} /> </svg>
    )
}

export function AssessmentIcon({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g id="Group_641" data-name="Group 641" transform="translate(-28 -208)"><circle id="Ellipse_32" data-name="Ellipse 32" cx="12" cy="12" r="12" transform="translate(28 208)" fill={color} /><text id="_" data-name="?" transform="translate(40 226)" fill={color === "#3E3B4E" ? "#F6BA42" : "#e8dbc3"} fontSize="16" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="-3.988" y="0">?</tspan></text></g></svg>
    )
}