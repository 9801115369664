import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from 'react-router-dom';
import homeBg from "../../images/img1.svg";
import status from "../../images/status.png";
import logo from "../../images/LogoParody.svg";
import recruit from "../../images/recruit.svg";
import styles from "../../styles/RequestCode.module.css";


const RequestStatus = () => {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        return navigate('/company-admin/dashboard');
    }

    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content}>
                            <img src={status} alt="" width="100%"></img>
                            <h3 className="mb-4 mt-5">Request sent successfully</h3>
                            <p>An email with a link to continue with the registration process will be sent by Pilipili Games once your request has been confirmed. You can always directly contact them at info@pilipiligames.be if necessary.</p>
                        </div>
                        <footer className={styles.footer}>
                            <p className="mt-5 pt-5">© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="logo" className={styles.logo1}></img>
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="people playing" className={styles.image1}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RequestStatus;