import React from 'react'
import { PuffLoader } from "react-spinners";

import styles from "../styles/loader.module.css";

const Loader = ({ loading }) => {
    if(!loading) return <></>;

    return (
        <div className={styles.container}>
            <PuffLoader color={"#fff"} loading={loading} size={200} />
        </div>
    )
}

export default Loader;
