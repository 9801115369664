import React, { useEffect, useState, navigate } from "react";
import { render } from "react-dom";
import Pagination from "react-paginating";
import { FaArrowRight } from 'react-icons/fa';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";
import useApi from "../../hooks/useApi";
import store from "../../api/store";
import adminApis from "../../api/admin";
import { Modal } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import styles from "../../styles/ApplicationPage.module.css";
import routes from "../../routes";
import validationSchemas from "../../utils/schemas";
import GameField from "../../components/form/GameField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, SubmitButton, TextField } from "../../components/form";
import FinishAssessmentButton from "../../components/form/FinishAssessmentButton";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";


const ApplicationPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));

    const [applicationToDelete, setApplicationToDelete] = useState();
    const deleteApplicationApi = useApi(adminApis.deleteApplication);


    const [updateApplication, setUpdateApplication] = useState();
    const updateApplicationApi = useApi(adminApis.updateApplication);

    const [modalShow, setModalShow] = React.useState(false);
    const getApplicationsApi = useApi(adminApis.getApplications);
    const [applications, setApplications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const applicationPerPage = 5;

    useEffect(() => {
        const getApplications = async () => {
            const res = await getApplicationsApi.request();
            if (res.ok) setApplications(res.data.data);
        }

        getApplications();
    }, []);


    const indexOfLastApplication = currentPage * applicationPerPage;
    const indexOfFirstApplication = indexOfLastApplication - applicationPerPage;
    const currentApplication = applications.items?.slice(indexOfFirstApplication, indexOfLastApplication);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const Pagination = ({applicationPerPage, totalApplicaltions, paginate}) => {
        const pageNumbers = [];
        for(let i = 1; i <= Math.ceil(totalApplicaltions / applicationPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className={currentPage === number ? "page-item active-page" : "page-item"}>
                            <Link to="" onClick={() => paginate(number)} className="page-link">{number}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        )
    }


    const handleApplicationDelete = async (code) => {
        const del = window.confirm("Are you sure you wants to delete this Application?");
        if (del) {
            const res = await deleteApplicationApi.request(code);
            if (res.ok) {
                helpers.toaster.success("Application Successfully Deleted");
                window.location.reload(false);
            }
            if (!res.ok ) {
                helpers.toaster.error("Unable to delete Application. Application has been assessed.");
            }
        }
    }
    
    const [selectedCompany, setSelectedCompany] = useState();
    const [filteredApplication, setFilteredApplication] = useState([]);
    const filteredApplicationApi = useApi(adminApis.filteredApplication);

    
    useEffect(() => {
        if (!selectedCompany > 0) return filteredApplicationApi.resetData();

        const items = {
            company: selectedCompany.map(item => item.value),
        };

        handleFilter(items);
    }, [selectedCompany]);

    // const handleFilters = async (filters) => {
    //     try {
    //         const res = await filteredApplicationApi.request(filters);
    //         console.log(res);
    //         console.log(filters);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const handleFilter = async (items) => {
        console.log(items.value);
        
        const res = await filteredApplicationApi.request({ companyId: items.value });
        if (res.data) {
            setFilteredApplication(res.data.data.items);
            console.log(res.data.data);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    const [company, setCompany] = useState([]);
    const getCompanyApi = useApi(adminApis.getCompany);

    // const skillApi = useApi(adminApis.getSkills);
    // const talentApi = useApi(adminApis.getTalents);
    const gamesApi = useApi(adminApis.getGames);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getCompany();
    }, []);

    // useEffect(() => {
    //     if (!isFiltered()) return filteredApplicationApi.resetData();

    //     const filters = {
    //         company: filteredApplication.map(item => item.value),
    //     };

    //     handleFilters(filters);
    // }, [filteredApplication]);

    const getData = async () => {
        const res = await gamesApi.request();
    }

    

    const getCompany = async () => {
        const res = await getCompanyApi.request();
        //const company = res.data.data.map((item) => helpers.renameKeys(item, { 'name': "label", 'id': 'value' }));
        const company = res.data.data.map(item => ({
            value: item.id, label: item.name
        }));
        setCompany(company);
    }

    const handleApplicationUpdate = async (data) => {
        console.log(data);
        const res = await updateApplicationApi.request({ ...data, code: updateApplication?.code });
        if (res.status === 200) {
            setModalVisible(false);
            helpers.toaster.success(res.data.responseMessage);
            //window.location.reload(false);
        }
        else {
            setModalVisible(false);
            helpers.toaster.error(res.data.responseMessage);
            //window.location.reload(false);
        }
        // if (res.ok) {
        //     console.log(res);
        //     setModalVisible(false);
        //     return setError({ message: "An error occured while updating application" });
        // };

        //return helpers.toaster.success("Application Successfully Updated");
    }

    const handlePreSubmit = (data) => {
        setError("");
        if (data?.games?.length > 0) return setModalVisible(true);

        setError({ message: "No game selected. please select a game." });
    }

    // const isFiltered = () => selectedCompany.length > 0;

    // const handleFilters = async (filters) => {
    //     try {
    //         const res = await filteredApplicationApi.request(filters);
    //         console.log(res);
    //         console.log(filters);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    ///////////////////////////////////////////////////////////////////////////////



    const getAssessorApi = useApi(adminApis.getAssessor);
    const [assessor, setAssessor] = useState([]);

    useEffect(() => {
        getAssessor();
    }, []);

    const getAssessor = async () => {
        const res = await getAssessorApi.request();
        if (res.ok) setAssessor(res.data.data);

        const assessor = res.data.data.map((item) => ({
            value: item.id, label: `${item.firstName} ${item.lastName}`
        }));
        setAssessor(assessor);
    }



    const [assignAssessor, setAssignAssessor] = useState();
    const [updateAssessor, setUpdateAssessor] = useState();
    const [selectedAssessor, setSelectedAssessor] = useState([]);
    const selectedAssessorApi = useApi(adminApis.assignAssessor);
    const updateAssessorApi = useApi(adminApis.updateAssessor);
    const deleteAssignedAssessorApi = useApi(adminApis.deleteAssignedAssessor);

    const handleSubmit = async () => {

        selectedAssessorApi.setLoading(true);

        //const assessorData = selectedAssessor.map((item) => helpers.renameKeys(item, { 'value': 'assessorId' }));

        const assessorData = selectedAssessor.map(item => item.value);

        const result = await selectedAssessorApi.request({ assessorId: assessorData, code: assignAssessor.code });

        if (result.ok) {
            helpers.toaster.success("Application Successfully Assigned");
            window.location.reload(false);
        }

        selectedAssessorApi.setLoading(false);
    }

    const handleUpdateAssessor = async () => {
        //e.preventDefault();
        selectedAssessorApi.setLoading(true);

        //const assessorData = selectedAssessor.map((item) => helpers.renameKeys(item, { 'value': 'assessorId' }));
        const assessorData = selectedAssessor.map(item => item.value);
        console.log({ assessorId: assessorData, code: assignAssessor.code });
        selectedAssessorApi.setLoading(false);
    }

    
    const handleDeleteAssessor = async (assessorID, applicationCode) => {
        //deleteAssignedAssessorApi.setLoading(true);
        
        // take a copy of all applications
        const newApplications = [...applications.items];
        // application = get application that has code equals applicationCode
        const application = newApplications.filter(app => app.code === applicationCode)[0];
        const indexOfApplication = newApplications.indexOf(app => app.code === applicationCode);
        // assessor = get assessor in application
        
        const newAssessors = application.assessors.filter(item => item.assessorId !== assessorID);
        application.assessors = [...newAssessors];
        newApplications[indexOfApplication] = application
        
        // replace original applications with the altered copy
        setApplications({...applications, items: newApplications});
        
        // delete the actual game from the backend
        deleteAssignedAssessorApi.request({ assessorId: assessorID, code: applicationCode });

        // show success
        helpers.toaster.success("Assessor Successfully Deleted");

        //const result = await deleteAssignedAssessorApi.request({assessorId: id, code: code});

        //deleteAssignedAssessorApi.setLoading(false);
        //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////


    return (
        <Wrapper activePage={routes.ApplicationPage}>
            <h2 align="center">Applications</h2>  
            
            <div className="row m-0 p-0">
                <div className="col-lg-10 m-auto">
                    <div className="col-md-4">
                        <h5> Filter by Company</h5>
                        <Select
                            isLoading={getCompanyApi.loading}
                            defaultValue={selectedCompany}
                            options={company}
                            value={selectedCompany}
                            labelledBy={"Filter by Company"}
                            onChange={(items) => handleFilter(items)}
                            isSearchable={true}
                        />
                        <button className="btn btn-light" onClick={() => window.location.reload(false)}>Clear Filter </button>
                        {/* <MultiSelect
                            isLoading={skillApi.loading}
                            shouldToggleOnHover
                            options={skillOptions}
                            className={styles.filter}
                            value={selectedSkillOptions}
                            labelledBy={"Filter by Styles"}
                            onChange={(items) => setSelectedSkillOptions(items)}
                        /> */}
                    </div>    
                    <div className={styles.card1}>
                        <table>                                
                            <tr>
                                <td className={styles.left}><span>Company Name</span></td>
                                <td className={styles.left}><span>Games</span></td>
                                <td className={styles.left}><span>Assigned Assessor</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            <tr><td colSpan={5}><hr></hr></td></tr>
                            <div>
                                {selectedCompany > 0 && filteredApplication.length === 0 && <div className={styles.noGames}>No applications found with the selected company</div>}
                            </div>
                            {applications.items?.length === 0 && !getApplicationsApi.loading && <tr>No Applications available </tr>}
                            {getApplicationsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                <>{(filteredApplication.length ? filteredApplication : currentApplication)?.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }} >
                                    <td className={styles.left}>{item.companyName}</td>
                                    <td className={styles.left}>{item.applicationGames.map((games) => <li>&#8226; {games.name}</li>)}</td>
                                    <td className={styles.left}>{item.assessors.map((assessor, key) => <li key={key}>&#8226; {assessor.firstName} {assessor.lastName} </li>)}</td>
                                    <td className={styles.left} style={{ padding: "10px 0px" }}><button hidden={item.isPlayed} onClick={() => setUpdateApplication(item)} className={styles.success}><FaEdit /> UPDATE</button>
                                        <button type="submit" hidden={item.isPlayed} onClick={() => handleApplicationDelete(item.code)} className={styles.danger}> <FaTrash /> {applicationToDelete === item.code && deleteApplicationApi.loading ? "DELETING..." : "DELETE"} </button>
                                        <button onClick={() => setAssignAssessor(item)} className={styles.success}> ASSIGN / UPDATE ASSESSOR</button>
                                    </td>
                                </tr>) }</>
                                // <>{!isFiltered() ? filteredApplication : currentApplication?.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }} >
                                //     <td className={styles.left}>{item.companyName}</td>
                                //     <td className={styles.left}>{item.applicationGames.map((games) => <font>{games.name} <br></br></font>)}</td>
                                //     <td className={styles.left}>{item.assessors.map((assessor, key) => <div key={key}>{assessor.firstName} {assessor.lastName}</div>)}</td>
                                //     <td className={styles.left} style={{ padding: "10px 0px" }}><button onClick={() => setUpdateApplication(item)} className={styles.success}><FaEdit /> UPDATE</button>
                                //         <button type="submit" onClick={() => handleApplicationDelete(item.code)} className={styles.danger}> <FaTrash /> {applicationToDelete === item.code && deleteApplicationApi.loading ? "DELETING..." : "DELETE"} </button>
                                //         <button onClick={() => setAssignAssessor(item)} className={styles.success}> ASSIGN / UPDATE ASSESSOR</button>
                                //     </td>
                                // </tr>) }</>
                            }
                        </table>

                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            title=""
                            show={assignAssessor?.code}
                            onHide={() => setAssignAssessor(null)}
                        >
                            <div className={styles.form1}>
                                <h4 align="left" className="mb-5">Assign an Assessor</h4>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mt-3 mb-3">{assignAssessor?.assessors.map(list =>
                                            <p style={{ textAlign: "left", border: "solid 1px #e0e0e0", margin: "5px", padding: "0px 10px", display: "inline-block", width: "48%" }}><button className="btn btn-danger" onClick={() => handleDeleteAssessor(list.assessorId, assignAssessor?.code)}>Delete </button> <label>{list.firstName} {list.lastName}</label>
                                            </p>)}
                                        </div>
                                        {/* <select className="form-control" onChange={(e) => setSelectedAssessor({ ...selectedAssessor, assessorId: e.target.value })}>
                                            <option selected disabled>Select an Assessor</option>
                                            {assessor.map((res) => (
                                                <option value={res.id}>{res.firstName} {res.lastName}</option>
                                            ))
                                            }
                                        </select> */}
                                        <MultiSelect
                                            isLoading={getAssessorApi.loading}
                                            shouldToggleOnHover
                                            options={assessor}
                                            value={selectedAssessor}
                                            labelledBy={"Select one or more Assessor"}
                                            onChange={setSelectedAssessor}
                                        />
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <button type="submit" onClick={handleSubmit} className={styles.sendCode}>{selectedAssessorApi.loading ? "Assigning..." : "Submit"}</button>
                                </div>
                            </div>
                        </Modal>  
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            title=""
                            show={updateAssessor?.code}
                            onHide={() => setUpdateAssessor(null)}
                        >
                            <div className={styles.form1}>
                                <h4 align="left" className="mb-5">Update Assigned Assessor</h4>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {updateAssessor?.assessors.map(list =>
                                            <p style={{ textAlign: "left", border: "solid 1px #e0e0e0", margin: "5px", padding: "0px 10px", display: "inline-block", width: "48%" }}>{list.assessorId}<button className="btn btn-danger" onClick={() => handleDeleteAssessor(list.assessorId, updateAssessor?.code)}>Delete </button> <label>{list.firstName} {list.lastName}</label>
                                            </p>)}
                                        {/* <select className="form-control" onChange={(e) => setSelectedAssessor({ ...selectedAssessor, assessorId: e.target.value })}>
                                            <option selected disabled>Select an Assessor</option>
                                            {assessor.map((res) => (
                                                <option value={res.id}>{res.firstName} {res.lastName}</option>
                                            ))
                                            }
                                        </select> */}
                                        <h5 className="mt-3 mb-3">Add More Assessors</h5>
                                        <MultiSelect
                                            isLoading={getAssessorApi.loading}
                                            shouldToggleOnHover
                                            options={assessor}
                                            value={selectedAssessor}
                                            labelledBy={"Select one or more Assessor"}
                                            onChange={setSelectedAssessor}
                                        />
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <button type="submit" onClick={handleUpdateAssessor} className={styles.sendCode}>{selectedAssessorApi.loading ? "Updating..." : "Submit"}</button>
                                </div>
                            </div>
                        </Modal>  

                        <Modal
                            centered
                            size="xl"
                            show={updateApplication?.code}
                            onHide={() => setUpdateApplication(null)}
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Update Application
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-4">
                                <Form
                                    validationSchema={validationSchemas.assessmentSchema}
                                    initialValues={{
                                        games: [],
                                        dateOfEvent: "",
                                        timeOfEvent: "",
                                    }}
                                    onSubmit={handleApplicationUpdate}
                                >

                                    <div className={styles.form}>
                                        <div>
                                            <h4>Current Application Games</h4>
                                            {/* {updateApplication?.applicationGames?.map(games => <div className="row card"><div className="col-md-3 m-2">Name: {games.name}</div><div className="col-md-7 m-2">Name: {games.description}</div><div className="col-md-4 m-2">Skills: {games.skill.map(skills => <label>{skills.name}{games.skill.length > 1 ? <>, &nbsp; </> : "."}</label>)}</div><div className="col-md-4 m-2">Competencies: {games.competency.map(competencies => <label>{competencies.name}{games.competency.length > 1 ? <>, &nbsp; </> : "."}</label>)}</div></div>)} */}
                                            <table className="table table-bordered"><thead> <td>Game Name</td> <td>Game Description</td> <td>Styles</td> <td>Competencies</td></thead>
                                                {updateApplication?.applicationGames?.map(games => <tr> <td>{games.name}</td><td>{games.description}</td><td>{games.skill.map(skills => <>{skills.name}{games.skill.length > 1 ? <>, &nbsp; </> : "."}</>)}</td><td>{games.competency.map(competencies => <label>{competencies.name}{games.competency.length > 1 ? <>,&nbsp;</> : "."}</label>)}</td></tr>)}
                                            </table>
                                        </div>
                                        {/* <div>
                                            <h4>Filter by:</h4>
                                            <div className={styles.filters}>
                                                <div>
                                                    <label className={styles.label}>Styles</label>
                                                    <MultiSelect
                                                        isLoading={skillApi.loading}
                                                        shouldToggleOnHover
                                                        options={skillOptions}
                                                        className={styles.filter}
                                                        value={selectedSkillOptions}
                                                        labelledBy={"Filter by Styles"}
                                                        onChange={(items) => setSelectedSkillOptions(items)}
                                                    />
                                                </div>
                                                <div>
                                                    <label className={styles.label}>Competences</label>
                                                    <MultiSelect
                                                        isLoading={talentApi.loading}
                                                        shouldToggleOnHover
                                                        className={styles.filter}
                                                        options={compentencyOptions}
                                                        labelledBy={"Filter by Competences"}
                                                        value={selectedCompentencyOptions}
                                                        onChange={(items) => setSeletedCompetencyOptions(items)}
                                                    />
                                                </div>
                                                {isFiltered() && (
                                                    <div className={styles.clear} onClick={() => {
                                                        setSelectedSkillOptions([]);
                                                        setSeletedCompetencyOptions([]);
                                                    }}>
                                                        <label className={styles.label}>Clear All</label>
                                                        <FontAwesomeIcon title="Clear" icon={faX} />
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}
                                        <h2 className={styles.title}>{gamesApi.loading ? "Loading" : "Showing"} Games</h2>
                                        <div className="mb-5 pb-1 text-end">
                                            {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                                            <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                                        </div>
                                        <p className={styles.subtitle}>Select games to proceed</p>
                                        {gamesApi.loading ?
                                            <div className={styles.loader}>
                                                <p>Loading...</p>
                                                <PuffLoader loading={true} />
                                            </div>
                                            :
                                            <>
                                                {gamesApi.data.map((game) => <GameField key={game.id} game={game} name={"games"} />)}
                                                <div>
                                                    {gamesApi.data.length === 0 && <div className={styles.noGames0}>No games available</div>}
                                                </div>
                                                <div className="mt-5 mb-5">
                                                    {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                                                    <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <Modal show={modalVisible} onHide={() => setModalVisible(false)} title="Recruit Pursuit Request Form">
                                        <form method="post" onSubmit="return false" className={styles.form1}>
                                            <span>Select date and time of event to complete your application</span>
                                            <div className="row mt-5">
                                                <div className="col-lg-6">
                                                    <label>Date of event</label>
                                                    <TextField type="date" className="form-control" name="dateOfEvent" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Time of event</label>
                                                    <TextField type="time" className="form-control" name="timeOfEvent" />
                                                </div>
                                            </div>
                                            <div className="text-center mt-5">
                                                <SubmitButton className={styles.sendCode} title={updateApplicationApi.loading ? "Submitting..." : "Submit"} />
                                            </div>
                                        </form>
                                    </Modal>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="px-5"><Pagination applicationPerPage={applicationPerPage} totalApplicaltions={applications.totalSize} paginate={paginate} /></div>
                </div>
            </div>
        </Wrapper>
    )
};

export default ApplicationPage;